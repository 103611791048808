import React, { Component } from 'react'
import { ToastContainer } from 'react-toastify'
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import NavBar from './components/common/navigation/navigationbar'
import HomePage from './components/public/homepage'
import LoginPage from './components/public/authentication/loginpage'
import RegisterMemeberPage from './components/public/authentication/registermemberpage'
import NewsPage from './components/public/news/newspage'
import ForumTopics from './components/public/forum/forumtopics'
import AddNewForumTopic from './components/public/forum/addnewforumtopic'
import ProtectedRoute from './components/common/protected/protectedRoute'
import MemberHome from './components/common/memberpages/memberhome'
import { getCurrentUser, getCurrentUserProfile } from './services/members/memberauth'
import LogOut from './components/public/authentication/logout'
import ForumDetail from './components/public/forum/forumdetail'
import NewsDetail from './components/public/news/newsdetail'
import GeneralFooter from './components/common/navigation/footerpage'
import ContactUs from './components/public/contactus'

//components 



class App extends Component {
  state ={
    currentProfile: [
      {
        firstName: '',
        lastName: '',
        emailAddress: '',
        memberId: '',
        profileImageUrl:''
      },
    ],
    member:[],
  }

  async componentDidMount() {
    //Called on page load
    const member = getCurrentUser()

    this.setState({ member })
    //get the admin profile here
    const { data: profile } = await getCurrentUserProfile()
    console.log('Profle')

    const currentProfile = 
      {
        firstName: profile.firstName,
        lastName: profile.lastName,
        emailAddress: profile.emailAddress,
        memberId: profile.memberId,
        profileImageUrl: profile.profileImageUrl
      }
    
    console.log(profile)

    this.setState({ currentProfile })
  }

  render(){
    const { member, currentProfile} = this.state
 
  return (
    <React.Fragment>
       <ToastContainer />
    {/* Navigation Bar Start */}
    <NavBar member={member}  currentProfile={currentProfile} />

    {/* Navigation Bar End */}
    <div className="content">
    <Switch>
           
           <Route path="/" exact component={HomePage} />   
           <Route path="/login"  component={LoginPage} />   
           <Route path="/registermember"  component={RegisterMemeberPage} /> 
           <Route path="/news"  component={NewsPage} /> 
           <Route path="/newsdetail/:blogTitle?"  component={NewsDetail} /> 
           <Route path="/forum"  component={ForumTopics} /> 
           <ProtectedRoute path="/addnewforumtopic"  component={AddNewForumTopic} /> 
           <ProtectedRoute path="/memberhome"  component={MemberHome} /> 
           <Route path="/forumdetail/:topicId?" component={ForumDetail} />
           <Route path="/contactus"  component={ContactUs} />

           
           <Route path="/logout" component={LogOut} />
          {/*} <Route path="/tutorialdetail/:tutorialTitle" component={TutorialDetail} />
           
           <Route path="/aboutme" component={AboutMe} />
           <Route path="/contactme" component={ContactMe} />
           
           
           <Route path="/portfolio/:categoryName?" component={Portfolioes} />
           <Route path="/portfoliodetail/:portfolioTitle" component={PortfolioDetail} />
           
           <Route path="/blogdetail/:blogTitle" component={BlogDetail} />

           <Route path="/tutorials/:categoryName?" component={Tutorials} />
           <Route path="/notfound" component={NotFound} />
  <Redirect to="/notfound" />*/}
          </Switch>
        </div>
        <GeneralFooter />
      {/*}  <GeneralFooter
        latestTutorials={latestTutorials}
        latestBlogs={latestBlogs}
/>*/}

    </React.Fragment>
  )
}
}

export default App;
