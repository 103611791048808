
import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import marker1 from "../../styletheme/media/banner/marker_1.png"
//marker 1 to 5
import marker2 from "../../styletheme/media/banner/marker_2.png"
import marker3 from "../../styletheme/media/banner/marker_3.png"
import marker4 from "../../styletheme/media/banner/marker_4.png"
import marker5 from "../../styletheme/media/banner/marker_5.png"
import people1 from "../../styletheme/media/banner/people_1.png"
import shape1 from "../../styletheme/media/banner/shape_1.png"
import mapline from "../../styletheme/media/banner/map_line.png"
import shape7 from "../../styletheme/media/figure/shape_7.png"
import team1 from "../../styletheme/media/team/team_1.jpg"
import team2 from "../../styletheme/media/team/team_2.jpg"
import team3 from "../../styletheme/media/team/team_3.jpg"
import team4 from "../../styletheme/media/team/team_4.jpg"
import team5 from "../../styletheme/media/team/team_5.jpg"
import team6 from "../../styletheme/media/team/team_6.jpg"
import team7 from "../../styletheme/media/team/team_7.jpg"
import chooseus from "../../styletheme/media/figure/why_choose_1.jpg"

import group1 from "../../styletheme/media/groups/groups_1.jpg"
import blog1 from "../../styletheme/media/blog/blog_1.jpg"
import { fetchLatestForumTopics } from '../../services/forum/forumTopicManagement';
import dateFormat from 'dateformat';
import { fetchLatestBlogs } from '../../services/blogs/blogService';



class HomePage extends Component
{
    state={
        heroImage : `https://firebasestorage.googleapis.com/v0/b/fordmavericknation.appspot.com/o/Marketing%2FFord-Maverick_2L-EcoBoost-AWD_Lariat_01.jpeg?alt=media&token=91009cd4-d950-4fdf-b6e0-f26ac49041f2`,
        latestForumTopics:[{
            topicId:'',
            forumTitle: '',
            topicDescription: '',
            numberOfPost: 0,
            activeMemberCount: 0,
            numberOfRepliesToComment: 0,
            likeReactionCount: 0,
            disLikeReactionCount:0,
            supportReactionCount: 0,
            numOfContributors: 0,
            commentReactions:[{
                reactionId:'',
                reactionImageUrl:'',
                reactionAuthorName:'',
                reactionAuthorId:'',

            }],
            mediaAttachments:[{
                mediaId:'',
                mediaUrl:'',
                mediaType:'',
                isBlocked: false
            }],
            authorProfile: {
                authorId:'',
                firstName:'',
                lastName:'',
                userName:'',
                profileImageUrl: ''
            },
            isBlocked: false,
            recordCreatedDate:''
        }],
        latestBlogs:[{
            blogId: "",
            blogTitle: "",
            authorDetail:[{
                userName: "",
                firstName: "",
                lastName: "",
                authorProfileImageUrl: ""
            }],
            numberOfComments: 0,
            blogCategory: "",
            blogTags:[{
                tagId: "",
                tagName: ""
            }],
            blogSections:[{
                sectionId: "",
                sectionTitle: "",
                sectionMainImageUrl: "",
                mediaUrls:[{
                    mediaType: "",
                    mediaCaption: "",
                    mediaUrl: "",
                    mediaPostedDate: ""
                }],
                sectionMainContent:""
            }],
            blogType:"",
            blogPromoImageUrl:"",
            blogOverview:"",
            blogReferences:[{
                referenceTitle:"",
                referenceAuthors:"",
                referenceBody:""
            }],
            recordCreatedDate:"",
            isDraft: false,
           

        }]
    }

    async componentDidMount(){
        const {data: latestForumTopics} = await fetchLatestForumTopics(5);

        console.log("Latest Topics");
        console.log(latestForumTopics);

        if(latestForumTopics){
            this.setState({latestForumTopics});
        }

        //fetch latest blogs
         
        const {data: latestBlogs} = await fetchLatestBlogs(5);

        console.log("Latest Blogs");
        console.log(latestBlogs);

        if(latestBlogs){
            this.setState({latestBlogs});
        }


    }

    render(){
        const { heroImage,latestForumTopics,latestBlogs} = this.state
        return(
        <React.Fragment>
            <div className="bg-link-water">    
           
                <section className="why-choose-fluid" style={{paddingTop: "7%"}}>
                    <div className="container-fluid full-width">
                        <div className="row no-gutters">
                            <div className="col-lg-6">
                                <div className="why-choose-content">
                                    <div className="content-box">
                                        <h2 className="item-title">Ford Maverick Nation</h2>
                                        <p>Home of the young and energetic Ford Maverick Pickup Truck.</p>
                                        <Link to="/forum" className="button-slide" >
                                            <span className="btn-text">Visit Our Forum</span>
                                            <span className="btn-icon">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="21px" height="10px">
                                                    <path fill-rule="evenodd" fill="rgb(255, 255, 255)" d="M16.671,9.998 L12.997,9.998 L16.462,6.000 L5.000,6.000 L5.000,4.000 L16.462,4.000 L12.997,0.002 L16.671,0.002 L21.003,5.000 L16.671,9.998 ZM17.000,5.379 L17.328,5.000 L17.000,4.621 L17.000,5.379 ZM-0.000,4.000 L3.000,4.000 L3.000,6.000 L-0.000,6.000 L-0.000,4.000 Z" />
                                                </svg>
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="why-choose-img">
                                    <div className="image-box">
                                        <img src={heroImage} alt="image" style={{height: "600px ", width: "951px"}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            
            
                <section className="why-choose-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="why-choose-box">
                                    <div className="item-subtitle">What We Do</div>
                                    <h2 className="item-title"><span>Why Join Our</span> Ford Maverick Nation?</h2>
                                    <p>Join the Ford Maverick Nation to connect with a like-minded community of Ford Maverick enthusiasts who are prospective owners or owners or just interested in this fresh take on a pickup truck to fit in our modern lifestyles. At Ford Maverick Nation, you will be able to ask questions and answer questions other community members may have. Soon, you will be able to share helpful guides on to-do projects or tips and tricks for your new Ford Maverick, among other exciting features coming soon.</p>
                                    <Link to="/registermember" className="button-slide">
                                        <span className="btn-text">Join Our Community</span>
                                        <span className="btn-icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="21px" height="10px">
                                                <path fill-rule="evenodd" fill="rgb(255, 255, 255)" d="M16.671,9.998 L12.997,9.998 L16.462,6.000 L5.000,6.000 L5.000,4.000 L16.462,4.000 L12.997,0.002 L16.671,0.002 L21.003,5.000 L16.671,9.998 ZM17.000,5.379 L17.328,5.000 L17.000,4.621 L17.000,5.379 ZM-0.000,4.000 L3.000,4.000 L3.000,6.000 L-0.000,6.000 L-0.000,4.000 Z" />
                                            </svg>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="why-choose-box">
                                    <ul className="features-list">
                                        <li>
                                            <div className="media">
                                                <div className="item-icon">
                                                    <i className="icofont-wechat"></i>
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="item-title">Meet Great People</h3>
                                                    <p>Meet like-minded people like you, who all share a passion for the Ford Maverick.</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="media">
                                                <div className="item-icon">
                                                    <i className="icofont-users"></i>
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="item-title">Forum Discussion</h3>
                                                    <p>Join engaging discussions that may validate or even enlighten you on some interesting fact or information about the Ford Maverick.</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="media">
                                                <div className="item-icon">
                                                    <i className="icofont-paper"></i>
                                                </div>
                                                <div className="media-body">
                                                    <h3 className="item-title">Share Your Knowledge</h3>
                                                    <p>Please share your knowledge and creativity with our community; sharing will lead to others sharing interesting information and facts that you may not have thought of and add value to each other.</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            

                <section className="section blog-grid" style={{paddingTop: "11px"}}>
                    <div className="container">
                        <div className="section-heading flex-heading">
                            <div className="row">
                                <div className="col-lg-5">
                                    <h2 className="heading-title">Discover Our Latest Discussions</h2>
                                </div>
                                <div className="col-lg-7">
                                    <p>There are always engaging discussions happening at Ford Maverick Nation. Search, and you will find a forum topic that matches your interest. If none is found, then create one and start the discussion.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                            {latestForumTopics.map((value,index)=>(
                                <div key={index} className="col-md-4">
                                    <div className="blog-box">
                                        <div className="blog-img">
                                            <Link to={`/forumdetail/${value.topicId}`}>
                                                {value.mediaAttachments.length > 0 &&
                                                    <img src={value.mediaAttachments[0].mediaUrl} alt="Blog" style={{height: "340px", width: "510px"}}/>
                                                }
                                                {value.mediaAttachments.length < 1 &&
                                                 <img src={blog1} alt="Blog" style={{height: "340px", width: "510px"}}/>
                                                }
                                               
                                            </Link>
                                            <div className="blog-date">
                                             
                                                <i className="icofont-calendar"></i> {dateFormat(value.recordCreatedDate, "mmmm dS, yyyy ")}
                                                </div>
                                        </div>
                                        <div className="blog-content">
                                            <h3 className="blog-title"><Link to={`/forumdetail/${value.topicId}`}>{value.forumTitle}</Link></h3>
                                            <ul className="entry-meta">
                                                <li><i className="icofont-ui-user"></i>by <Link to={`/forumdetail/${value.topicId}`}>{value.authorProfile.userName}</Link></li>
                                               
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section className="section blog-grid" style={{paddingTop: "11px"}}>
                    <div className="container">
                        <div className="section-heading flex-heading">
                            <div className="row">
                                <div className="col-lg-5">
                                    <h2 className="heading-title">Latest Maverick News &amp; Articles</h2>
                                </div>
                                <div className="col-lg-7">
                                    <p>There are exciting News and Articles found at Ford Maverick Nation every week.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">

                    {latestBlogs.map((value,index)=>(

                            <div key={index} className="col-md-4">
                                <div className="blog-box">
                                    <div className="blog-img">
                                        <Link to={`/newsdetail/${value.blogTitle}`}>
                                            <img src={value.blogPromoImageUrl} alt="Blog Image" style={{height: "340px", width: "510px"}}/>
                                        </Link>
                                        <div className="blog-date"><i className="icofont-calendar"></i>{dateFormat(value.recordCreatedDate, "mmmm dS, yyyy ")}</div>
                                    </div>
                                    <div className="blog-content">
                                        <h3 className="blog-title"><Link to={`/newsdetail/${value.blogTitle}`}>{value.blogTitle}</Link></h3>
                                        <ul className="entry-meta">
                                            <li><i className="icofont-ui-user"></i>by <Link to={`/newsdetail/${value.blogTitle}`}>
                                                {value.authorDetail.length > 0 && 
                                                    <React.Fragment>
                                                        {value.authorDetail[0].firstName} {value.authorDetail[0].lastName}
                                                    </React.Fragment>
                                                }  
                                                {value.authorDetail.length < 1 &&
                                                    <span>Admin</span>
                                                 }
                                            </Link></li>
                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>
        ))}
                   
                        </div>
                    </div>
                </section>
                <section className="banner-newsletter" hidden>
                    <ul className="section-cloud">
                        <li><img src="media/figure/cloud_1.png" alt="shape"/></li>
                        <li><img src="media/figure/cloud_2.png" alt="shape"/></li>
                        <li><img src="media/figure/cloud_2.png" alt="shape"/></li>
                        <li><img src="media/figure/cloud_1.png" alt="shape"/></li>
                    </ul>
                    <div className="container">
                        <ul className="section-shape">
                            <li><img src="media/figure/shape_1.png" alt="shape"/></li>
                            <li><img src="media/figure/shape_2.png" alt="shape"/></li>
                            <li><img src="media/figure/shape_3.png" alt="shape"/></li>
                            <li><img src="media/figure/shape_4.png" alt="shape"/></li>
                            <li><img src="media/figure/shape_5.png" alt="shape"/></li>
                        </ul>
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="newsletter-box">
                                    <h2 className="item-title">Subscribe Cirkle Newsletter</h2>
                                    <p>Subscribe to be the first one to know about updates, new features and much more! Enter your email</p>
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Enter your e-mail"/>
                                        <div className="input-group-append">
                                            <button className="button-slide" type="button">
                                                <span className="btn-text">Subscribe Now</span>
                                                <span className="btn-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="21px" height="10px">
                                                        <path fill-rule="evenodd" fill="#ffffff" d="M16.671,9.998 L12.997,9.998 L16.462,6.000 L5.000,6.000 L5.000,4.000 L16.462,4.000 L12.997,0.002 L16.671,0.002 L21.003,5.000 L16.671,9.998 ZM17.000,5.379 L17.328,5.000 L17.000,4.621 L17.000,5.379 ZM-0.000,4.000 L3.000,4.000 L3.000,6.000 L-0.000,6.000 L-0.000,4.000 Z" />
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
         </div>   
        
        </React.Fragment>

    )
    }
}

export default HomePage;