
import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import blog4 from "../../../styletheme/media/blog/blog_4.jpg"
import blog11 from "../../../styletheme/media/blog/blog_11.jpg"
import blog10 from "../../../styletheme/media/blog/blog_10.jpg"//" media/blog/blog_4.jpg"
import reaction1 from "../../../styletheme/media/figure/reaction_1.png"
import reaction2 from "../../../styletheme/media/figure/reaction_2.png"
import reaction3 from "../../../styletheme/media/figure/reaction_3.png"
import dateFormat from 'dateformat';
import chat7 from "../../../styletheme/media/figure/chat_7.jpg"
import { fetchLatestBlogs, fetchTargetBlogByTitle } from '../../../services/blogs/blogService';
import Iframe from 'react-iframe';
import { toast } from 'react-toastify';

class NewsDetail extends Component
{
    state ={
        blog:{
            blogId: "",
            blogTitle: "",
            authorDetail:[{
                userName: "",
                firstName: "",
                lastName: "",
                authorProfileImageUrl: ""
            }],
            numberOfComments: 0,
            blogCategory: "",
            blogTags:[{
                tagId: "",
                tagName: ""
            }],
            blogSections:[{
                sectionId: "",
                sectionTitle: "",
                sectionMainImageUrl: "",
                mediaUrls:[{
                    mediaType: "",
                    mediaCaption: "",
                    mediaUrl: "",
                    mediaPostedDate: ""
                }],
                sectionMainContent:""
            }],
            blogType:"",
            blogPromoImageUrl:"",
            blogOverview:"",
            blogReferences:[{
                referenceTitle:"",
                referenceAuthors:"",
                referenceBody:""
            }],
            recordCreatedDate:"",
            isDraft: false,
           

        },
        latestBlogs:[{
            blogId: "",
            blogTitle: "",
            authorDetail:[{
                userName: "",
                firstName: "",
                lastName: "",
                authorProfileImageUrl: ""
            }],
            numberOfComments: 0,
            blogCategory: "",
            blogTags:[{
                tagId: "",
                tagName: ""
            }],
            blogSections:[{
                sectionId: "",
                sectionTitle: "",
                sectionMainImageUrl: "",
                mediaUrls:[{
                    mediaType: "",
                    mediaCaption: "",
                    mediaUrl: "",
                    mediaPostedDate: ""
                }],
                sectionMainContent:""
            }],
            blogType:"",
            blogPromoImageUrl:"",
            blogOverview:"",
            blogReferences:[{
                referenceTitle:"",
                referenceAuthors:"",
                referenceBody:""
            }],
            recordCreatedDate:"",
            isDraft: false,
           

        }]
    }

    async componentDidMount(){

        const blogTitle = this.props.match.params.blogTitle
        const {data: blog} = await fetchTargetBlogByTitle(blogTitle);
        if(blog.blogTitle !== ""){
            this.setState({blog});
        }
        //fetch latest news or blogs
         
        const {data: latestBlogs} = await fetchLatestBlogs(5);

        if(latestBlogs){
            this.setState({latestBlogs});
        }


    }

    async updateUI(value) {
        //window.history.replaceState(value.portfolioCaseTitle)
        this.props.history.push(`/newsdetail/${value.blogTitle}`)
      
        const { data: blog } = await fetchTargetBlogByTitle(value.blogTitle)
    
    
        if(blog.blogTitle !== ""){
            this.setState({blog});
        }
        else
        { 
            console.log("No Blog Found");
            toast.warning("No Blog or News found, please go to News");
    
        }
    
        //fetch latest news or blogs
        const {data: latestBlogs} = await fetchLatestBlogs(5);

        if(latestBlogs){
            this.setState({latestBlogs});
        }
    
    
      }

    render(){
        const { blog,latestBlogs} = this.state
        return(
        <React.Fragment>
             
             <div className="page-content">


                <div className="container">
                    <div className="block-box user-single-blog">
                        <div className="blog-thumbnail">
                            <img src={blog.blogPromoImageUrl} alt="Blog Image" style={{height: "500px", width: "1170px"}}/>
                        </div>
                        <div className="blog-content-wrap">
                            <div className="blog-entry-header">
                                <div className="entry-category">
                                    <a href="#">Blog</a>
                                    <a hidden href="#">Inspiration</a>
                                </div>
                                <h2 className="entry-title">{blog.blogTitle}</h2>
                                <div className="row align-items-center">
                                    <div className="col-lg-8">
                                        <ul className="entry-meta">
                                            <li>
                                                <img src={blog.authorDetail[0].authorProfileImageUrl} alt="Author Image" style={{height: "44px", width: "44px"}}/>
                                                By <a href="#">{blog.authorDetail[0].firstName} {blog.authorDetail[0].lastName}</a>
                                            </li>
                                            <li><i className="icofont-calendar"></i> {dateFormat(blog.recordCreatedDate, "mmmm dS, yyyy ")}</li>
                                            <li hidden><i className="icofont-comment"></i> Comments: 05</li>
                                            <li hidden><i className="icofont-share"></i> Share: 02</li>
                                        </ul>
                                    </div>
                                    <div hidden className="col-lg-4">
                                        <ul className="blog-share">
                                            <li><a href="#" className="bg-fb"><i className="icofont-facebook"></i></a></li>
                                            <li><a href="#" className="bg-twitter"><i className="icofont-twitter"></i></a></li>
                                            <li><a href="#" className="bg-dribble"><i className="icofont-dribbble"></i></a></li>
                                            <li><a href="#" className="bg-youtube"><i className="icofont-youtube"></i></a></li>
                                            <li><a href="#" className="bg-behance"><i className="icofont-behance"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="blog-content">
                                <p>{blog.blogOverview} </p>

                                <section >
                                    {blog.blogSections.map((value,index)=>(
                                        <section className="row" key={index}>
                                        <h3 className="item-title">{value.sectionTitle}</h3>
                                        <p>
                                            {value.sectionMainContent}
                                        </p>


                                        <img src={value.sectionMainImageUrl} alt="Section Main Image" style={{height: "500px", width: "1170px"}}/>
                                        
                                        {value.mediaUrls.map((value,index)=>(
                                        <div className="col-md-6">
                                        
                                            <div key={index}  className="content-img ">
                                                {value.mediaType === "Image" &&
                                                    <React.Fragment>
                                                         <img src={value.mediaUrl} alt="Blog Section Mini" style={{height: "380px", width: "500px"}}/>
                                                         <p>{value.mediaCaption}</p>
                                                     </React.Fragment>
                                                }
                                                {value.mediaType === "Video" &&
                                                    <React.Fragment>
                                                        <Iframe url={"https://www.youtube.com/embed/" +value.mediaUrl }
                                                        width="100%"
                                                        height="465px"
                                                        id="blogVideoVideo"
                                                        className="card-image"
                                                        display="initial"
                                                        frameborder="0" 
                                                        allowfullscreen
                                                        position="relative"/>
                                                         <p>{value.mediaCaption}</p>
                                                     </React.Fragment>
                                                }
                                            </div>
                                            
                                        </div>
                                        ))}
                                        </section>
                                    ))}

                                   


                                </section>
         
                            </div>
                            <div hidden className="blog-footer">
                                <div className="item-label">Choose your <span>Reaction!</span></div>
                                <div className="reaction-icon">
                                    <a href="#"><img src="media/figure/reaction_1.png" alt="Like"/></a>
                                    <a href="#"><img src="media/figure/reaction_6.png" alt="Like"/></a>
                                    <a href="#"><img src="media/figure/reaction_2.png" alt="Like"/></a>
                                    <a href="#"><img src="media/figure/reaction_7.png" alt="Like"/></a>
                                    <a href="#"><img src="media/figure/reaction_3.png" alt="Like"/></a>
                                    <a href="#"><img src="media/figure/reaction_5.png" alt="Like"/></a>
                                </div>
                            </div>
                            <div hidden className="blog-comment-form">
                                <h3 className="item-title">Leave a Comment</h3>
                                <form>
                                    <div className="row gutters-20">
                                        <div className="col-lg-4 form-group">
                                            <input type="text" name="name" className="form-control" placeholder="Name"/>
                                        </div>
                                        <div className="col-lg-4 form-group">
                                            <input type="email" name="email" className="form-control" placeholder="E-mail"/>
                                        </div>
                                        <div className="col-lg-4 form-group">
                                            <input type="text" name="website" className="form-control" placeholder="website"/>
                                        </div>
                                        <div className="col-lg-12 form-group">
                                            <textarea name="message" id="message" className="form-control textarea" placeholder="Comments" cols="30" rows="7"></textarea>
                                        </div>
                                        <div className="col-lg-12 form-group">
                                            <input type="submit" className="submit-btn" name="post-comment" value="Post Comment"/>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="realated-blog">
                        <div className="block-box blog-heading">
                            <h3 className="heading-title">Related Posts</h3>
                        </div>
                        <div className="row">
                        {latestBlogs.map((value,index)=>(
                            <div key={index} className="col-lg-4">
                                <div className="block-box user-blog">
                                    <div className="blog-img">
                                        <Link path={`/newsdetail/${value.blogTitle}`}
                                            onClick={() => this.updateUI(value)}><img src={value.blogPromoImageUrl} alt="Blog" style={{height: "250px", width: "376px"}}/></Link>
                                    </div>
                                    <div className="blog-content">
                                        <div className="blog-category">
                                            <Link path={`/newsdetail/${value.blogTitle}`}
                                            onClick={() => this.updateUI(value)}>Blog</Link>
                                            <a hidden href="#">Inspiration</a>
                                        </div>
                                        <h3 className="blog-title"><Link  path={`/newsdetail/${value.blogTitle}`}
                                            onClick={() => this.updateUI(value)}>{value.blogTitle}</Link></h3>
                                        <div className="blog-date"><i className="icofont-calendar"></i> {dateFormat(value.recordCreatedDate, "mmmm dS, yyyy ")}</div>
                                        <p>{value.blogOverview.length > 100? value.blogOverview.substring(0,100) + "..." : value.blogOverview}</p>
                                    </div>
                                    <div className="blog-meta" hidden>
                                        <ul>
                                            <li className="blog-reaction">
                                                <div className="reaction-icon">
                                                    <img src="media/figure/reaction_1.png" alt="icon"/>
                                                    <img src="media/figure/reaction_2.png" alt="icon"/>
                                                    <img src="media/figure/reaction_3.png" alt="icon"/>
                                                </div>
                                                <div className="meta-text">+ 15 others</div>
                                            </li>
                                            <li><i className="icofont-comment"></i> 05</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                         
                        ))}



                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
    }
}

export default NewsDetail;