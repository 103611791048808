import React, { Component } from 'react'
import Joi from 'joi-browser'

class Form extends Component{

    state ={
        data: {},
        errors: {}
    };

    validate = () =>{

        const options = {abortEarly: false};
        const {error} = Joi.validate(this.state.data,this.schema, options);
        if(!error) return null;

        const errors = {};

        for (let item of error.details)
            errors[item.path[0]] = item.message;

            return errors;
    };
      validateProperty = ({name,value}) =>{
        const obj = {[name]: value};
        const schema = {[name]: this.schema[name]};
        const {error}  = Joi.validate(obj,schema );

        return error ? error.details[0].message : null; 
    };

   handleSubmit = e =>{
        e.preventDefault();
        //get the values from the form
       // var emailAddress = this.emailAddress.current.value;
       // var password = document.getElementById('txtPassword').value;
        //call the server to save the changes
        
        const errors = this.validate();
        console.log(errors);
        this.setState({errors : errors || {} });
        if(errors) return;

        this.doSubmit();
        
    }


    handleChange = ({currentTarget: input}) =>{
        //input validation
        const errors = {...this.state.errors};

        const errorMessage = this.validateProperty(input);

        if(errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];
        //data seccount (model)
        const data = {...this.state.data};

        data[input.name] = input.value;
        this.setState({data, errors});
    }


    renderButton(label,styleClass = "btn btn-success"){
        return(
         <React.Fragment>


        <button 
            disabled={this.validate()}
           className={styleClass}
           >{label}</button>    
         </React.Fragment>

       
        )
    }

    renderUpdateButton(label,styleClass = "btn btn-primary"){
        return(
            <button 
           
            className={styleClass}>{label}</button>
        )
    }

}

export default Form;