import http from '../general/httpService';
import { apiEndpoint } from '../../constants/config.json'

//create method
export function createNewForumTopic(forumTopic){
     return http.post(apiEndpoint + '/forumManagement/api/create_new_forum_topic',forumTopic);
 }

 //create_new_forum_topic_comment
 export function createNewForumComment(forumComment){
    return http.post(apiEndpoint + '/forumManagement/api/create_new_forum_topic_comment',forumComment);
}

 
//fetch_all_forum_topics
export function fetchAllForumTopics(){
    return http.get(apiEndpoint + '/forumManagement/api/fetch_all_forum_topics');
}

export function fetchAllForumTopicCount(){
    return http.get(apiEndpoint + '/forumManagement/api/fetch_forum_topic_count');
}
//fetch_target_forum_topic_by_id/:topicId

export function fetchTargetForumTopic(topicId){
    return http.get(apiEndpoint + '/forumManagement/api/fetch_target_forum_topic_by_id/'+ topicId);
}

//fetch_all_forum_topic_comments_by_id/:topicId
export function fetchTargetForumComments(topicId){
    return http.get(apiEndpoint + '/forumManagement/api/fetch_all_forum_topic_comments_by_id/'+ topicId);
}

//fetch_latest_forum_topics/:limit

export function fetchLatestForumTopics(limit){
    return http.get(apiEndpoint + '/forumManagement/api/fetch_latest_forum_topics/'+ limit);
}