//blogManagement
import http from '../general/httpService';
import { apiEndpoint } from '../../constants/config.json'

export function fetchAllBlogs(){
    return http.get(apiEndpoint + '/blogManagement/api/fetch_all_blogs');
}


export function fetchLatestBlogs(limit){
    return http.get(apiEndpoint + '/blogManagement/api/fetch_latest_blogs/'+ limit);
}

export function fetchTargetBlogByTitle(blogTitle){
    return http.get(apiEndpoint + '/blogManagement/api/fetch_blog_by_title/'+ blogTitle);
}