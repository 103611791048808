import React from 'react';
import {Link} from 'react-router-dom';
import chat1 from "../../../styletheme/media/figure/chat_1.jpg"
import chat2 from "../../../styletheme/media/figure/chat_2.jpg"
import chat3 from "../../../styletheme/media/figure/chat_3.jpg"
import chat5 from "../../../styletheme/media/figure/chat_5.jpg"
import group1 from "../../../styletheme/media/groups/user_group1.jpg" 

const TopicCard  = ({authorName,topicDescription, forumTitle, mediaUrl,mediaType,path,key,mediaAttachments,numberOfPost,numOfContributors,contributors,...rest}) => {
    return (   
       
             
        <div key={key} className="col-xl-3 col-lg-4 col-md-6"  {...rest}>
        <div className="widget-author user-group">
            <div className="author-heading">
            
                <div className="">
                    <Link to={path}>
                    {mediaAttachments.length <= 0 &&
                        <img src={group1} alt="cover" style={{height: "190px", width: "190px"}}/>
                    }
                    {mediaAttachments.length >= 1 && mediaAttachments[0].mediaType === "Image" &&
                        <img src={mediaAttachments[0].mediaUrl} alt="cover" style={{height: "190px", width: "190px"}}/>
                    }
                    </Link>
                    
                </div>
                <div className="profile-name" style={{paddingLeft: "1%"}}>
                    <h4 className="author-name"><Link to={path}>{forumTitle}</Link></h4>
                    <div className="author-location">
                        {topicDescription.length > 100? topicDescription.substring(0,100) + "..." : topicDescription}
                    </div>
                </div>
            </div>
            <div style={{textAlign: "left"}}> 
            <span>Recent Contributors</span>
            <br/>
                <ul className="member-thumb">
                    <li><img src={chat1} alt="member"/></li>
                    <li><img src={chat2} alt="member"/></li>
                    <li><img src={chat3} alt="member"/></li>
                    <li><img src={chat5} alt="member"/></li>
                    <li><i className="icofont-plus"></i></li>
                </ul>
                <br/>
                <ul className="author-statistics">
                <li>
                    <Link to={path}><span className="item-number">{numberOfPost}</span> <span className="item-text">COMMENT POSTS</span></Link>
                </li>
                <li>
                    <Link to={path}><span className="item-number">{numOfContributors}</span> <span className="item-text">ALL CONTRIBUTORS</span></Link>
                </li>
            </ul>
            </div>
           
           
        </div>
    </div>
           
            
        );
    }

 
export default TopicCard;




