import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//theme


 
    import "../src/styletheme/dependencies/bootstrap/css/bootstrap.min.css";
    import "../src/styletheme/dependencies/icofont/icofont.min.css";
    import "../src/styletheme/dependencies/slick-carousel/css/slick.css";
    import "../src/styletheme/dependencies/slick-carousel/css/slick-theme.css";
    import "../src/styletheme/dependencies/magnific-popup/css/magnific-popup.css";
    import "../src/styletheme/dependencies/sal.js/sal.css";
    import "../src/styletheme/dependencies/mcustomscrollbar/jquery.mCustomScrollbar.min.css";
    import "../src/styletheme/dependencies/select2/css/select2.min.css";

    import "../src/styletheme/assets/css/app.css";
    import {
      BrowserRouter,
      Switch,
      Route,
      Link
    } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
