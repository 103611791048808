
import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import { fetchLatestBlogs } from '../../../services/blogs/blogService';
import blog_4 from "../../../styletheme/media/blog/blog_4.jpg"//" media/blog/blog_4.jpg"
import reaction1 from "../../../styletheme/media/figure/reaction_1.png"
import reaction2 from "../../../styletheme/media/figure/reaction_2.png"
import reaction3 from "../../../styletheme/media/figure/reaction_3.png"
import dateFormat from 'dateformat';

class NewsPage extends Component
{
    state ={
        blogs:[{
            blogId: "",
            blogTitle: "",
            authorDetail:[{
                userName: "",
                firstName: "",
                lastName: "",
                authorProfileImageUrl: ""
            }],
            numberOfComments: 0,
            blogCategory: "",
            blogTags:[{
                tagId: "",
                tagName: ""
            }],
            blogSections:[{
                sectionId: "",
                sectionTitle: "",
                sectionMainImageUrl: "",
                mediaUrls:[{
                    mediaType: "",
                    mediaCaption: "",
                    mediaUrl: "",
                    mediaPostedDate: ""
                }],
                sectionMainContent:""
            }],
            blogType:"",
            blogPromoImageUrl:"",
            blogOverview:"",
            blogReferences:[{
                referenceTitle:"",
                referenceAuthors:"",
                referenceBody:""
            }],
            recordCreatedDate:"",
            isDraft: false,
           

        }]
    }

    async componentDidMount(){

        //fetch all blogs
         
        const {data: blogs} = await fetchLatestBlogs(5);

        console.log("All Blogs");
        console.log(blogs);

        if(blogs){
            this.setState({blogs});
        }


    }

    render(){
        const { blogs} = this.state
        return(
            <React.Fragment>
             
<div className="bg-link-water">
             <div className="container">
      
                <div hidden className="block-box user-top-header">
                    <ul className="menu-list">
                        <li className="active"><a href="#">Timeline</a></li>
                        <li><a href="#">About</a></li>
                        <li><a href="#">Friends</a></li>
                        <li><a href="#">Groups</a></li>
                        <li><a href="#">Photos</a></li>
                        <li><a href="#">Videos</a></li>
                        <li><a href="#">Badges</a></li>
                        <li><a href="#">Blogs</a></li>
                        <li><a href="#">Forums</a></li>
                        <li>
                            <div className="dropdown">
                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                    ...
                                </button>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <a className="dropdown-item" href="#">Shop</a>
                                    <a className="dropdown-item" href="#">Blog</a>
                                    <a className="dropdown-item" href="#">Others</a>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div hidden className="block-box user-search-bar justify-content-between">
                    <div className="box-item">
                        <div className="item-show-title">Total 20 Posts</div>
                    </div>
                    <div className="box-item search-filter">
                        <div className="dropdown">
                            <label>Order By:</label>
                            <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">Newest Post</button>
                            <div className="dropdown-menu dropdown-menu-right">
                                <a className="dropdown-item" href="#">All Post</a>
                                <a className="dropdown-item" href="#">Newest Post</a>
                                <a className="dropdown-item" href="#">Oldest Post</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div  className="block-box user-search-bar justify-content-between" style={{paddingTop: "13%"}}>
                    <div className="box-item">
                        <h3 className="item-title">Ford Maverick Blogs and News</h3>
                    </div>
                
                </div>
                <div className="row gutters-20">
                {blogs.map((value,index)=>(
                    <div key={index} className="col-lg-4 col-md-6">

                        <div className="block-box user-blog">
                            <div className="blog-img">
                               
                                <Link to={`/newsdetail/${value.blogTitle}`}>
                                            <img src={value.blogPromoImageUrl} alt="Blog Image" style={{height: "250px", width: "376px"}}/>
                                </Link>
                            </div>
                            <div className="blog-content">
                                <div className="blog-category">
                                    <Link to={`/newsdetail/${value.blogTitle}`}>Blog</Link>
                          
                                </div>
                                <h3 className="blog-title"><Link to={`/newsdetail/${value.blogTitle}`}>{value.blogTitle}</Link></h3>
                                <div className="blog-date"><i className="icofont-calendar"></i> {dateFormat(value.recordCreatedDate, "mmmm dS, yyyy ")}</div>
                                <p>{value.blogOverview.length > 100? value.blogOverview.substring(0,100) + "..." : value.blogOverview}</p>
                            </div>
                            <div hidden className="blog-meta">
                                <ul>
                                    <li className="blog-reaction">
                                        <div className="reaction-icon">
                                            <img src={reaction1} alt="icon"/>
                                            <img src={reaction2} alt="icon"/>
                                            <img src={reaction3} alt="icon"/>
                                        </div>
                                        <div className="meta-text">+ 15 others</div>
                                    </li>
                                    <li><i className="icofont-comment"></i> 05</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                ))}
   
                </div>
                <div className="load-more-post" hidden>
                    <a href="#" className="item-btn"><i className="icofont-refresh"></i>Load More Posts</a>
                </div>
            </div>
            </div>
            </React.Fragment>

    )
    }
}

export default NewsPage;