import React from 'react'

const MultiLineInput = ({name, label,value,placeholder,type,error,onChange,cols,rows,className = "form-control textarea"}) =>{
    return (
        <div className="form-group">
            <label>{label}:</label>
            <textarea
                type={type}
                className={className}
                name={name}
                error={error}
                value={value} 
                placeholder={placeholder} 
                cols={cols}
                rows={rows}
                onChange={onChange}
            />
              {error &&<div className="alert alert-danger" >
                {error}
            </div>}
        </div>
    );
}

export default MultiLineInput;

