
import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import Form from '../../components/common/reusable/form';
import uuid from 'react-uuid'
import Joi from 'joi-browser';
import {toast} from 'react-toastify';
import Input from '../common/reusable/input';
import MultiLineInput from '../common/reusable/multilineinput';
import { createContactRequest } from '../../services/contact/contactRequestService';


class ContactUs extends Form
{

    state={
        data:{
             //contactRequestId: "",
                requestorName:"",
                requestorEmailAddress: "",
                subject: "",
                messageDetail: "",
                //recordCreatedDate:""
            
            
                
            
        },
        errors:{

        },
        baseState:{

        },
    
    }

    schema ={
        //contactRequestId: Joi.string().optional(),
        requestorName: Joi.string().required().label('Name'),
        requestorEmailAddress: Joi.string().required().label('Email Address'),
        subject: Joi.string().required().label('Subject'),
        messageDetail: Joi.string().required().label('Message')
    };
    //set initial state to reset form on successful submit
    constructor(props){
        super(props)
    
        // preserve the initial state in a new object
        this.baseState = this.state ///>>>>>>>>> note this one.
    }
    resetForm = () => {
        this.setState(this.baseState) ///>>>>>>>>> note this one.
    }
    doSubmit = async () =>{

        try{
        const {data} = this.state;
        //get the current location
        const {state} = this.props.location; 
      
             const requestorName = data.requestorName;
             const requestorEmailAddress = data.requestorEmailAddress;
             const subject = data.subject;
             const messageDetail = data.messageDetail;
           
              //Need to send this to the api and get the token
              //need to add the author info

              const contactRequest ={
                contactRequestId: uuid(),//set a random ID
                requestorName: requestorName,
                requestorEmailAddress: requestorEmailAddress,
                subject: subject,
                messageDetail: messageDetail


            }
            
            await createContactRequest(contactRequest);
            console.log("Submitted");

            //Thank user
            toast.success("Your Contact Request successfully received.");
            //get the current location
           // const {state} = this.props.location; 
           //reset the form and initial state
           this.resetForm();
            //force a reload of the page
            //GO back to the video management
           // window.location = state ? state.from.pathname : "/forum";

            }
            catch(ex){
                if(ex.response && ex.response.status === 400){
                    const errors ={...this.state.errors};
                    errors.forumTitle = ex.response.data;

                    toast.error(ex.response.data);
                    //Update the UI
                    this.setState({errors});
                }
            }

    }

    render(){
        const {data, errors } = this.state;
        return(
            <React.Fragment>
             
             <div className="bg-link-water">
                <section className="about-us">
                    <div className="container">
                    <section class="contact-page">
           <br/>
            <div class="contact-box-wrap" style={{paddingTop: "10%"}}>
                <div class="container">
                    <div class="contact-form">
                        <div class="row">
                            <div class="col-lg-7">
                                <div class="contact-box">
                                    <h3 class="item-title">Get In Touch </h3>
                                    <form id="contact-form" onSubmit={this.handleSubmit}>
                                        <div class="row gutters-20">
                                            <div class="col-lg-12 form-group">
                                                 
                                        <Input
                                            type="text" 
                                            className="form-control"
                                            id="requestorName"
                                            name="requestorName"
                                            error={errors.requestorName}
                                            value={data.requestorName} 
                                            label="Name" 
                                            placeholder="Please enter your name." 
                                            onChange={this.handleChange}
                                         />
                                            </div>
                                            <div class="col-lg-12 form-group">
                                            <Input
                                            type="email" 
                                            className="form-control"
                                            id="requestorEmailAddress"
                                            name="requestorEmailAddress"
                                            error={errors.requestorEmailAddress}
                                            value={data.requestorEmailAddress} 
                                            label="Email Address" 
                                            placeholder="Please enter your email address." 
                                            onChange={this.handleChange}
                                         />
                                            </div>
                                          
                                            <div class="col-lg-12 form-group">
                                           <Input
                                            type="text" 
                                            className="form-control"
                                            id="subject"
                                            name="subject"
                                            error={errors.subject}
                                            value={data.subject} 
                                            label="Subject" 
                                            placeholder="Please enter your message subject." 
                                            onChange={this.handleChange}
                                         />
                                            </div>
                                            <div class="col-12 form-group">
                                            <MultiLineInput
                                           name="messageDetail" 
                                           id="messageDetail"
                                            error={errors.messageDetail}
                                            value={data.messageDetail} 
                                            label={"Message"}
                                           className="form-control textarea" 
                                          cols="30" rows="3"
                                            onChange={this.handleChange}
                                         />
                                            </div>
                                            <div class="col-12 form-group">
                                              
                                                {this.renderButton("Send Us Message")}
                                            </div>
                                        </div>
                                        <div class="form-response"></div>
                                    </form>
                                </div>
                            </div>
                         
                        </div>
                    </div>
                </div>
            </div>
        </section>
                        
                            </div>
                </section>
            </div>
            </React.Fragment>

    )
    }
}

export default ContactUs;