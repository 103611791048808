import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom'
import logo from '../../../styletheme/assets/logo/simple-logo-with-words.png';
const GeneralFooter = () => {
    return (
      <React.Fragment>
        {/*main-footer */}
  
        <footer className="footer-wrap footer-dashboard" style={{backgroundColor: "rgb(99 99 99)"}}>
                <div className="main-footer">
                    <div className="container">
                        <div className="row row-cols-lg-4 row-cols-sm-2 row-cols-1">
                            <div className="col">
                                <div className="footer-box">
                                    <div className="footer-logo">
                                        <Link to="/"><img src={logo} alt="Logo"/></Link>
                                    </div>
                                    <p style={{color: "white"}}>Home of the young and energetic Ford Maverick Pickup Truck.</p>
                                </div>
                            </div>
                            <div className="col d-flex justify-content-lg-center">
                                <div className="footer-box">
                                    <h3 className="footer-title" style={{color: "white"}}>
                                        Important Links
                                    </h3>
                                    <div className="footer-link" >
                                        <ul>
                                            <li><Link style={{color: "white"}} to="/">Home</Link></li>
                                            <li><Link style={{color: "white"}} to="/news" >News</Link></li>
                                            <li><Link style={{color: "white"}} to="/forum">Forum</Link></li>
                                            <li hidden><Link style={{color: "white"}} to="/shop">Shop</Link></li>
                                            <li ><Link style={{color: "white"}} to="/contactus">Contact Us</Link></li>

                                            {/*
                                                 <ul className="menu-content">
                                <li className="header-nav-item">
                                    <NavLink to="/" className="menu-link active">Home</NavLink>
                                </li>
                                <li className="header-nav-item">
                                    <NavLink to="/news" className="menu-link active">News</NavLink>
                                </li>
                                <li className="header-nav-item">
                                    <NavLink to="/forum" className="menu-link active">Forum</NavLink>
                                </li>
                                <li className="header-nav-item">
                                    <NavLink hidden  to="/shop" className="menu-link active">Shop</NavLink>
                                </li>
                                <li className="header-nav-item">
                                    <NavLink to="/contactus" className="menu-link active">Contact Us</NavLink>
                                </li>
                                            */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                   
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="footer-copyright" style={{color: "white"}}>  Copyright &copy;{new Date().getFullYear()}{' '}
                <Link to={{ pathname: "https://vengarre.com/" }} target="_blank" style={{color: "white"}}><b>Vengarre Consulting</b></Link>, All Rights Reserved.</div>
                </div>
            </footer>
      </React.Fragment>
    )
  }
  
  export default GeneralFooter
  