import http from '../general/httpService';
import { apiEndpoint } from '../../constants/config.json'


 
//fetch_all_forum_members

export function fetchAllForumAdminMembers(){
    return http.get(apiEndpoint + '/forumManagement/api/fetch_all_forum_admin_members');
}

export function fetchAllForumMembers(){
    return http.get(apiEndpoint + '/forumManagement/api/fetch_all_forum_members');
}

//get members count
//fetch_forum_member_count

export function fetchAllMembersCount(){
    return http.get(apiEndpoint + '/forumManagement/api/fetch_forum_member_count');
}

