
import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import logo from '../../../styletheme/assets/logo/simple-logo.png';
import banner1 from "../../../styletheme/media/figure/widget_banner_1.png"
import cover1 from "../../../styletheme/media/figure/cover_1.jpg"
import group1 from "../../../styletheme/media/groups/user_group1.jpg" 
import chat1 from "../../../styletheme/media/figure/chat_1.jpg"
import chat2 from "../../../styletheme/media/figure/chat_2.jpg"
import chat3 from "../../../styletheme/media/figure/chat_3.jpg"
import chat4 from "../../../styletheme/media/figure/chat_4.jpg"
import chat5 from "../../../styletheme/media/figure/chat_5.jpg"
import reaction1 from "../../../styletheme/media/figure/reaction_1.png"
import reaction2 from "../../../styletheme/media/figure/reaction_2.png"
import reaction3 from "../../../styletheme/media/figure/reaction_3.png"
import reaction4 from "../../../styletheme/media/figure/reaction_4.png"
import reaction5 from "../../../styletheme/media/figure/reaction_5.png"
import reaction6 from "../../../styletheme/media/figure/reaction_6.png"
import reaction7 from "../../../styletheme/media/figure/reaction_7.png"
import group9 from "../../../styletheme/media/groups/groups_9.jpg"
import group10 from "../../../styletheme/media/groups/groups_10.jpg"
import group11 from "../../../styletheme/media/groups/groups_11.jpg"
import group12 from "../../../styletheme/media/groups/groups_12.jpg"
import post5 from "../../../styletheme/media/figure/post_12.jpg"
import { createNewForumComment, fetchAllForumTopicCount, fetchAllForumTopics, fetchLatestForumTopics, fetchTargetForumComments, fetchTargetForumTopic } from '../../../services/forum/forumTopicManagement';
import { toast } from 'react-toastify';
import TopicCard from '../../common/common/topiccard';
import { fetchAllForumAdminMembers, fetchAllForumMembers, fetchAllMembersCount } from '../../../services/forum/forummembers';
import { getCurrentUserProfile } from '../../../services/members/memberauth';
import Form from '../../common/reusable/form';
import MultiLineInput from '../../common/reusable/multilineinput';
import '../../../index.css'
import uuid from 'react-uuid'
import Joi from 'joi-browser';
import Iframe from 'react-iframe';
import dateFormat from 'dateformat';

class ForumDetail extends Form
{

    state={
        targetTopicId: '',
        advertisementBanner: "https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/Marketing%2FAdvert1-01-01.png?alt=media&token=a0ea459c-0bca-49df-ada4-fc7034cd681d",
        topicsCount: 0,
        totalMembersCount: 0,
        forumTopics:[{
            topicId:'',
            forumTitle: '',
            topicDescription: '',
            numberOfPost: 0,
            activeMemberCount: 0,
            numberOfRepliesToComment: 0,
            likeReactionCount: 0,
            disLikeReactionCount:0,
            supportReactionCount: 0,
            numOfContributors: 0,
            commentReactions:[{
                reactionId:'',
                reactionImageUrl:'',
                reactionAuthorName:'',
                reactionAuthorId:'',

            }],
            mediaAttachments:[{
                mediaId:'',
                mediaUrl:'',
                mediaType:'',
                isBlocked: false
            }],
            authorProfile: {
                authorId:'',
                firstName:'',
                lastName:'',
                profileImageUrl: ''
            },
            isBlocked: false,
            recordCreatedDate:''
    

        }],
        forumComments:[{
         
                 topicCommentReplyId:'',
                 topicId:'',//reply
                 commentDescription:'',
                 numberOfRepliesToComment: 0,
                 likeReactionCount: 0,
                 disLikeReactionCount: 0,
                 supportReactionCount: 0,
                 commentReactions:[{
                     reactionId:'',
                     reactionImageUrl:'',
                     reactionAuthorName:'',
                     reactionAuthorId:'',
 
                 }],
                 mediaAttachments:[{
                     mediaId:'',
                     mediaUrl:'',
                     mediaType:'',
                     isBlocked: false
                 }],
                 replierAuthorProfile: {
                     authorId:'',
                     userName: '',
                     firstName:'',
                     lastName:'',
                     profileImageUrl: ''
                 },
                 isBlocked: false,
                 recordCreatedDate: ''
        }],
        forumTopic:{
            topicId:'',
            forumTitle: '',
            topicDescription: '',
            numberOfPost: 0,
            activeMemberCount: 0,
            numberOfRepliesToComment: 0,
            likeReactionCount: 0,
            disLikeReactionCount:0,
            supportReactionCount: 0,
            numOfContributors: 0,
            commentReactions:[{
                reactionId:'',
                reactionImageUrl:'',
                reactionAuthorName:'',
                reactionAuthorId:'',

            }],
            mediaAttachments:[{
                mediaId:'',
                mediaUrl:'',
                mediaType:'',
                isBlocked: false
            }],
            authorProfile: {
                authorId:'',
                firstName:'',
                lastName:'',
                userName:'',
                profileImageUrl: ''
            },
            isBlocked: false,
            recordCreatedDate:''
    

        },
        members:[{
                memberId:'',
                firstName:'',
                userName:'',
                lastName:'',
                emailAddress:'',
        }],
        adminMembers:[{
                memberId:'',
                firstName:'',
                userName:'',
                lastName:'',
                emailAddress:'',
        }],
        currentProfile: 
            {
              firstName: '',
              lastName: '',
              emailAddress: '',
              userName: '',
              memberId: '',
              profileImageUrl:''
            },
            data:{
               // topicCommentReplyId:'',
                //topicId:'',//reply
                commentDescription:'',
                /*numberOfRepliesToComment: 0,
                likeReactionCount: 0,
                disLikeReactionCount: 0,
                supportReactionCount: 0,
                commentReactions:[{
                    reactionId:'',
                    reactionImageUrl:'',
                    reactionAuthorName:'',
                    reactionAuthorId:'',

                }],
                mediaAttachments:[{
                    mediaId:'',
                    mediaUrl:'',
                    mediaType:'',
                    isBlocked: false
                }],
                replierAuthorProfile: {
                    authorId:'',
                    firstName:'',
                    lastName:'',
                    profileImageUrl: ''
                },*/
               // isBlocked: false,
               // recordCreatedDate: ''

            },
            errors:{

            },
            baseState:{
    
            },
            latestForumTopics:[{
                topicId:'',
                forumTitle: '',
                topicDescription: '',
                numberOfPost: 0,
                activeMemberCount: 0,
                numberOfRepliesToComment: 0,
                likeReactionCount: 0,
                disLikeReactionCount:0,
                supportReactionCount: 0,
                numOfContributors: 0,
                commentReactions:[{
                    reactionId:'',
                    reactionImageUrl:'',
                    reactionAuthorName:'',
                    reactionAuthorId:'',
    
                }],
                mediaAttachments:[{
                    mediaId:'',
                    mediaUrl:'',
                    mediaType:'',
                    isBlocked: false
                }],
                authorProfile: {
                    authorId:'',
                    firstName:'',
                    lastName:'',
                    userName:'',
                    profileImageUrl: ''
                },
                isBlocked: false,
                recordCreatedDate:''
            }]

    }
    schema ={
        //topicCommentReplyId: Joi.string().optional(),
        //topicId: Joi.string().required().label('Topic Id'),
        commentDescription: Joi.string().required().label('Comment Description'),
        /*numberOfRepliesToComment: Joi.number().optional().label('Number Of Replies To Comment'),
        likeReactionCount: Joi.number().optional().label('Like Reaction Count'),
        disLikeReactionCount: Joi.number().optional().label('Dis-Like Reaction Count'),
        supportReactionCount: Joi.number().optional().label('Support Reaction Count'),
        commentReactions: Joi.array().optional().label('Comment Reactions'),
        mediaAttachments: Joi.array().optional().label('Media Attachments'),
        replierAuthorProfile: Joi.object().optional().label('Replier Author Profile'),*/
        //isBlocked: Joi.boolean().optional().label('Is Blocked?')


    };

//set initial state to reset form on successful submit
    constructor(props){
        super(props)
    
        // preserve the initial state in a new object
        this.baseState = this.state ///>>>>>>>>> note this one.
    }
    resetForm = () => {
       // this.setState(this.baseState) ///>>>>>>>>> note this one.

    }
    doSubmit = async () =>{

        try{
        const {data,currentProfile,targetTopicId} = this.state;
        //get the current location
        const {state} = this.props.location; 
              //call the api method

            

            // const forumTitle = data.forumTitle;
            // const topicDescription = data.topicDescription;
         
           
              //Need to send this to the api and get the token
              //need to add the author info

             const authorProfileDetail ={
                authorId: currentProfile.memberId,
                firstName: currentProfile.firstName,
                lastName: currentProfile.lastName,
                userName: currentProfile.userName,
                profileImageUrl: currentProfile.profileImageUrl
              }
              console.log("Author Detail")
              console.log(authorProfileDetail);

              const forumComment ={
                topicCommentReplyId: uuid(),//set a random ID
                topicId: targetTopicId,
                commentDescription: data.commentDescription,
                replierAuthorProfile: authorProfileDetail,
                numberOfRepliesToComment: 0,
                likeReactionCount: 0,
                disLikeReactionCount: 0,
                supportReactionCount: 0,
                //commentReactions: data.commentReactions,//this is to be implemented one day
                //mediaAttachments: data.mediaAttachments,//TODO
                isBlocked: false,

            }
            console.log("Forum Comment Detail")
            console.log(forumComment);
            //temp
           
            //Update this with the create forum comment method
            await createNewForumComment(forumComment);
            console.log("Submitted");

            //Thank user
            toast.success("Forum Comment successfully sent.");
            //get the current location
           // const {state} = this.props.location; 
           //reset the form and initial state
           //this.resetForm();
           //document.getElementByid('commentDescription').value = '';
           data.commentDescription = "";
           this.setState({data });
           //this.setState({commentDescription :""})
            //force a reload of the page
            //GO back to the video management
            //window.location = state ? state.from.pathname : "/forum";
            //TODO need to add a refresh feature to the page
            toast.success("Please refresh the page, to see your comment.");

            }
            catch(ex){
                if(ex.response && ex.response.status === 400){
                    const errors ={...this.state.errors};
                    errors.forumTitle = ex.response.data;

                    toast.error(ex.response.data);
                    //Update the UI
                    this.setState({errors});
                }
            }

    }

async componentDidMount(){

    const targetTopicId = this.props.match.params.topicId;
    this.setState({ targetTopicId });


    const { data: forumTopic } = await fetchTargetForumTopic(targetTopicId)


    if(forumTopic)
    {
        console.log("Topic ");
        console.log(forumTopic );
        this.setState({ forumTopic });
    }
    else
    { 
        console.log("No Topic");
        toast.warning("Sorry, no forum topic found. Please add one :)");

    }
    //load the other common methods
    this.LoadCommonMethods(targetTopicId);
}

//async LoadCommonMethods(targetTopicId){
    LoadCommonMethods = async targetTopicId => {
    
    try{
        //get the current logged in user that is signed in
        const { data: currentProfile } = await getCurrentUserProfile()
        console.log('Profle')
        console.log(currentProfile);
        if(currentProfile)
        {
            this.setState({ currentProfile });
        }
    }
    catch(ex)
    {
        console.log(ex);
    }

    //side bar data
        //fetch all admin members
        const {data: adminMembers} = await fetchAllForumAdminMembers();

        if(adminMembers){
            this.setState({adminMembers});
        }
    
        //fetch members
        
        const {data: members} = await fetchAllForumMembers();
    
        if(members){
            this.setState({members});
        }

        console.log("Topic Id");
        console.log(targetTopicId);

        const {data: forumComments} = await fetchTargetForumComments(targetTopicId);
        console.log("Comments");
        console.log(forumComments);
        if(forumComments){
            this.setState({forumComments});
        }
    
        //fetch latest five forum topics
        const {data: latestForumTopics} = await fetchLatestForumTopics(5);

        console.log("Latest Topics");
        console.log(latestForumTopics);

        if(latestForumTopics){
            this.setState({latestForumTopics});
        }
}

async updateUI(value) {
    //window.history.replaceState(value.portfolioCaseTitle)
    this.props.history.push(`/forumdetail/${value.topicId}`)
  
    const { data: forumTopic } = await fetchTargetForumTopic(value.topicId)


    if(forumTopic)
    {
        console.log("Topic ");
        console.log(forumTopic );
        this.setState({ forumTopic });
    }
    else
    { 
        console.log("No Topic");
        toast.warning("Sorry, no forum topic found. Please add one :)");

    }

    //load the other common methods
    this.LoadCommonMethods(value.topicId);


  }

    render(){
        const { advertisementBanner,forumTopic,currentProfile, adminMembers,members ,data, errors,forumComments,latestForumTopics} = this.state
        return(
            <React.Fragment>
             


             <div className="bg-link-water">
                <div className="banner-user banner-forum">
                    <div className="banner-content">
                        <div className="media">
                            <div className="item-img">
                            <img src={forumTopic.authorProfile.profileImageUrl} alt="User" style={{height: "115px", width: "115px"}}/>
                            </div>
                            <div className="media-body">
                                <h3 className="item-title">{forumTopic.forumTitle}</h3>
                                <div className="item-subtitle">By {forumTopic.authorProfile.userName}</div>
                                <ul hidden className="item-social">
                                    <li><a href="#" className="bg-fb"><i className="icofont-facebook"></i></a></li>
                                    <li><a href="#" className="bg-twitter"><i className="icofont-twitter"></i></a></li>
                                    <li><a href="#" className="bg-dribble"><i className="icofont-dribbble"></i></a></li>
                                    <li><a href="#" className="bg-youtube"><i className="icofont-brand-youtube"></i></a></li>
                                    <li><a href="#" className="bg-behance"><i className="icofont-behance"></i></a></li>
                                </ul>
                                <ul hidden className="user-meta">
                                    <li>Group Type: <span>Public</span></li>
                                    <li>Posts: <span>30</span></li>
                                    <li>Members: <span>2,590</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8">
                        <div className="block-box forum-info block-box">
                            <div className="widget-heading">
                                <h3 className="widget-title">Topic Description</h3>
       
                            </div>
                            <p>{forumTopic.topicDescription}</p>
                        </div>
                </div>
                <div className="row">
                    <div className="col-lg-8">
                        {/*Show this if the user is logged in else give the option to login or sign up to comment */}
                        {currentProfile.userName != "" &&
                            <form  onSubmit={this.handleSubmit} className="block-box post-input-tab forum-post-input">
                                <div className="media">
                                    <div className="item-img">
                                        <img src={currentProfile.profileImageUrl} alt="img" style={{height: "44px", width: "44px"}}/>
                                    </div>
                                    <div className="media-body">

                                        <MultiLineInput
                                           name="commentDescription" 
                                           id="commentDescription"
                                           error={errors.commentDescription}
                                           value={data.commentDescription} 
                                           label={"Topic Overview"}
                                           className="form-control textarea" 
                                           placeholder= {"Share what are you thinking " +currentProfile.userName+" . . ."} 
                                           cols="30" 
                                           rows="4"
                                            onChange={this.handleChange}
                                         />
                                    </div>
                                </div>
                                <div className="post-footer">
                                    <div hidden className="insert-btn">
                                        <a href="#"><i className="icofont-image"></i></a>
                                        <a href="#"><i className="icofont-clip"></i></a>
                                        <a href="#"><i className="icofont-tags"></i></a>
                                        <a href="#"><i className="icofont-location-pin"></i></a>
                                    </div>
                                    <div className="submit-btn">
                                    
                                        {this.renderButton("Post Comment")}
                                    </div>
                                </div>
                            </form>
                        }
                        {currentProfile.userName == "" &&
                            <div className="col-lg-8">
                                <div className="block-box forum-info block-box" style={{textAlign: "center"}}> 
                                    <div className="widget-heading" >
                                        <h3 className="widget-title" >Seems you aren't sign in: </h3>
                                        <p>Please sign in or register to comment.</p>
                                    </div>
                                    <div className="submit-btn">
                                    <Link className="btn btn-primary" to="/login">Sign In to Comment</Link>
                                    <span style={{padding: "2%"}}>Or</span>
                                    <Link className="btn btn-success" to="/registermember">Sign Up to Comment</Link>
                                    </div>
                                </div>
                            </div>
                        }

                        {/* Row Start*/}
                        {forumComments.map((value,index)=>(

                        <div key={index} className="block-box post-view">
                            <div className="post-header">
                                <div className="media">
                                    <div className="user-img">
                                        <img src={value.replierAuthorProfile.profileImageUrl} alt="User Profile Image"
                                            style={{height: "44px",width: "44px"}}/>
                                    </div>
                                    <div className="media-body">
                                        <div className="user-title"><a href="#">{value.replierAuthorProfile.userName != null? value.replierAuthorProfile.userName : value.replierAuthorProfile.firstName + " " + value.replierAuthorProfile.lastName }</a> <i className="icofont-check"></i></div>
                                        <ul className="entry-meta">
                                            <li className="meta-privacy"><i className="icofont-world"></i> Public</li>
                                            <li className="meta-time">
                                                {/*2 minutes ago*/}
                                                {dateFormat(value.recordCreatedDate, "mmmm dS, yyyy ")}
                                                
                                                </li>
                                        </ul>
                                    </div>
                                </div>
                                <div hidden className="dropdown">
                                    <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                        ...
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a className="dropdown-item" href="#">Close</a>
                                        <a className="dropdown-item" href="#">Edit</a>
                                        <a className="dropdown-item" href="#">Delete</a>
                                    </div>
                                </div>
                            </div>
                            <div className="post-body">
                                <p>{value.commentDescription}</p>
                                <div className="post-img">
                                    {value.mediaAttachments.map((value,index)=>(
                                        <div>
                                            {value.mediaType === "Image" &&
                                        
                                                <img key={index} src={value.mediaUrl} style={{height: "720px",width: "300px"}} alt="Post Image"/>
                                            }
                                            {value.mediaType === "Video" &&
                                                //Need to add Video URL attachment
                                        
                                                <Iframe url={value.mediaUrl }
                                                    width="100%"
                                                    height="465px"
                                                    id="commentPostVideo"
                                                    className="card-image"
                                                    display="initial"
                                                    frameborder="0" 
                                                    allowfullscreen
                                                    position="relative"/>
                                            }
                                        </div>
                                    ))}
                                   
                                </div>
                                <div hidden className="post-meta-wrap">
                                    <div className="post-meta">
                                        <div className="post-reaction">
                                            <div className="reaction-icon">
                                                <img src={reaction1} alt="icon"/>
                                                <img src={reaction2}alt="icon"/>
                                                <img src={reaction3} alt="icon"/>
                                            </div>
                                            <div className="meta-text">15</div>
                                        </div>
                                    </div>
                                    <div className="post-meta">
                                        <div className="meta-text">2 Comments</div>
                                        <div className="meta-text">05 Share</div>
                                    </div>
                                </div>
                            </div>
                            <div hidden className="post-footer">
                                <ul>
                                    <li className="post-react">
                                        <a href="#"><i className="icofont-thumbs-up"></i>React!</a>
                                        <ul className="react-list">
                                            <li><a href="#"><img src={reaction1} alt="Like"/></a></li>
                                            <li><a href="#"><img src={reaction2} alt="Like"/></a></li>
                                            <li><a href="#"><img src={reaction4} alt="Like"/></a></li>
                                            <li><a href="#"><img src={reaction2} alt="Like"/></a></li>
                                            <li><a href="#"><img src={reaction7} alt="Like"/></a></li>
                                            <li><a href="#"><img src={reaction6} alt="Like"/></a></li>
                                            <li><a href="#"><img src={reaction5} alt="Like"/></a></li>
                                        </ul>
                                    </li>
                                    <li><a href="#"><i className="icofont-comment"></i>Comment</a></li>
                                    <li className="post-share">
                                        <a href="javascript:void(0);" className="share-btn"><i className="icofont-share"></i>Share</a>
                                        <ul className="share-list">
                                            <li><a href="#" className="color-fb"><i className="icofont-facebook"></i></a></li>
                                            <li><a href="#" className="color-messenger"><i className="icofont-facebook-messenger"></i></a></li>
                                            <li><a href="#" className="color-instagram"><i className="icofont-instagram"></i></a></li>
                                            <li><a href="#" className="color-whatsapp"><i className="icofont-brand-whatsapp"></i></a></li>
                                            <li><a href="#" className="color-twitter"><i className="icofont-twitter"></i></a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        ))}

                    {/* Row ENd*/}
                       
            
            
                        <div hidden className="block-box load-more-btn">
                            <a href="#" className="item-btn"><i className="icofont-refresh"></i>Load More Posts</a>
                        </div>
                    </div>
                <div className="col-lg-4 widget-block widget-break-lg">

                    <div className="widget widget-groups">
                            <div className="widget-heading">
                                <h3 className="widget-title">Latest Topics</h3>
                                <div hidden className="dropdown">
                                    <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                        ...
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a className="dropdown-item" href="#">Close</a>
                                        <a className="dropdown-item" href="#">Edit</a>
                                        <a className="dropdown-item" href="#">Delete</a>
                                    </div>
                                </div>
                            </div>
                            <div className="group-list">
                             {latestForumTopics.map((value,index)=>(
                                   <div key={index} className="media">
                                  
                                        <div className="media-body">
                                            <h4 className="item-title"><Link 
                                            path={`/forumdetail/${value.topicId}`}
                                            onClick={() => this.updateUI(value)}
                                            >{value.forumTitle}</Link></h4>
                                            <div className="item-member">started {dateFormat(value.recordCreatedDate, "mmmm dS, yyyy ")}</div>
                                        </div>
                                    </div>
                             ))}

                            </div>
                            <div className="see-all-btn">
                                <Link to="/forum" className="item-btn">See All Topics</Link>
                            </div>
                        </div>

               
                        <div className="widget widget-memebers">
                            <div className="widget-heading">
                                <h3 className="widget-title">Group Administrators</h3>
                              
                            </div>
                            <div className="members-list">
                                {/* Row Start*/}

                                {adminMembers.map((value,index)=>(
              
                                        <div key={index} className="media">
                                            <div className="item-img">
                                                <a href="#">
                                                    <img src={value.profileImageUrl} alt="Chat" style={{height: "44px", width: "44px",background: "white"}}/>
                                                </a>
                                            </div>
                                            <div className="media-body">
                                                <h4 className="item-title"><a href="#">{value.firstName} {value.lastName}</a></h4>
                                                <div className="item-username">@{value.userName} </div>
                                                <div className="member-status online"><i className="icofont-check"></i></div>
                                            </div>
                                        </div>



                                ))}
                           

                                 {/* Row End*/}

                            </div>
                        </div>
                        <div className="widget widget-memebers">
                            <div className="widget-heading">
                                <h3 className="widget-title">Members</h3>
                             
                            </div>
                            <ul hidden className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" href="#newest-member" role="tab" aria-selected="true">NEWEST</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#popular-member" role="tab" aria-selected="false">POPULAR</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#active-member" role="tab" aria-selected="false">ACTIVE</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="newest-member" role="tabpanel">
                                    <div className="members-list">
                                  {/* Row Start*/}
                                    {members.map((value,index)=>(

                                        <div key={index} className="media">
                                            <div className="item-img">
                                                <a href="#">
                                                     <img src={value.profileImageUrl} alt="Chat" style={{height: "44px", width: "44px",background: "white"}}/>
                                                </a>
                                            </div>
                                            <div className="media-body">
                                                <h4 className="item-title"><a href="#">{value.userName}</a></h4>
                                                <div className="member-status offline"><i className="icofont-check"></i></div>
                                            </div>
                                        </div>

                                    ))}
                                                            

                                    {/* Row End*/}

                                    </div>
                                </div>
                     
                            </div>
                        </div>


                        <div className="widget widget-banner" style={{    backgroundColor: "#f6a43f",padding: "0px 0px 0px",borderBottom: "20px"}}>
                            <h3 className="item-title">Advertisement</h3>
                            <div className="item-subtitle">Vengarre Consulting</div>
                           
                            <Link to={{ pathname: "https://vengarre.com/requestquote" }} target="_blank" className="item-btn">
                                <span className="btn-text">Request a Quote</span>
                                <span className="btn-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="21px" height="10px">
                                        <path fill-rule="evenodd" d="M16.671,9.998 L12.997,9.998 L16.462,6.000 L5.000,6.000 L5.000,4.000 L16.462,4.000 L12.997,0.002 L16.671,0.002 L21.003,5.000 L16.671,9.998 ZM17.000,5.379 L17.328,5.000 L17.000,4.621 L17.000,5.379 ZM-0.000,4.000 L3.000,4.000 L3.000,6.000 L-0.000,6.000 L-0.000,4.000 Z" />
                                    </svg>
                                </span>
                            </Link>
                            <div className="item-img">
                                <img src={advertisementBanner} alt="banner" style={{height: "300px",width: "100%"}}/>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
            </React.Fragment>

    )
    }
}

export default ForumDetail;