import React, { Component, useState } from 'react'
import {NavLink,Link} from 'react-router-dom';
import logo from '../../../styletheme/assets/logo/simple-logo-with-words.png';
import styled from 'styled-components';
import '../../../index.css';
import cart_1 from "../../../styletheme/media/figure/cart_1.jpg"
import cart_2 from "../../../styletheme/media/figure/cart_2.jpg"
import chat_5 from "../../../styletheme/media/figure/chat_5.jpg"
import '../../../index.css';

const NavBar = ({member,currentProfile}) => {
 //On load get the profile info from me
//mobile start 
const COLORS = {
    primaryDark: '#2f55d4',
    primaryLight: '#B6EDC8',
  }

  const MenuLabel = styled.label`
    background-color: ${COLORS.primaryDark};
    position: fixed;
    top: 2rem;
    right: 1rem;
    border-radius: 50%;
    height: 90px;
    width: 90px;
    cursor: pointer;
    z-index: 1000;
   
    box-shadow: 0 1rem 3rem rgba(182, 237, 200, 0.3);
    text-align: center;
  `

  const NavBackground = styled.div`
    position: fixed;
    top: 6.5rem;
    right: 6.5rem;
    background-image: radial-gradient(
      ${COLORS.primaryDark},
      ${COLORS.primaryLight}
    );
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    z-index: 600;
    transform: ${(props) => (props.clicked ? 'scale(80)' : 'scale(0)')};
    transition: transform 0.8s;
  `

  const Icon = styled.span`
    position: relative;
    background-color: ${(props) => (props.clicked ? 'transparent' : 'white')};
    width: 3rem;
    height: 2px;
    display: inline-block;
    margin-top: 3.5rem;
    transition: all 0.3s;
    &::before,
    &::after {
      content: '';
      background-color: white;
      width: 3rem;
      height: 2px;
      display: inline-block;
      position: absolute;
      left: 0;
      transition: all 0.3s;
    }
    &::before {
      top: ${(props) => (props.clicked ? '0' : '-0.8rem')};
      transform: ${(props) => (props.clicked ? 'rotate(135deg)' : 'rotate(0)')};
    }
    &::after {
      top: ${(props) => (props.clicked ? '0' : '0.8rem')};
      transform: ${(props) =>
        props.clicked ? 'rotate(-135deg)' : 'rotate(0)'};
    }
    ${MenuLabel}:hover &::before {
      top: ${(props) => (props.clicked ? '0' : '-1rem')};
    }
    ${MenuLabel}:hover &::after {
      top: ${(props) => (props.clicked ? '0' : '1rem')};
    }
  `

  const Navigation = styled.nav`
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 600;
    width: ${(props) => (props.clicked ? '100%' : '0')};
    opacity: ${(props) => (props.clicked ? '1' : '0')};
    transition: width 0.8s, opacity 0.8s;
  `

  const List = styled.ul`
    position: absolute;
    list-style: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  `
  const ItemLink = styled(NavLink)`
    display: inline-block;
    font-size: 3rem;
    font-weight: 300;
    text-decoration: none;
    color: ${COLORS.primaryLight};
    padding: 1rem 2rem;
    background-image: linear-gradient(
      120deg,
      transparent 0%,
      transparent 50%,
      #fff 50%
    );
    background-size: 240%;
    transition: all 0.4s;
    &:hover,
    &:active {
      background-position: 100%;
      color: ${COLORS.primaryDark};
      transform: translateX(1rem);
    }
  `

  const [click, setClick] = useState(false)
  const handleClick = () => setClick(!click)


//mobbile end
    
        return ( 
            <React.Fragment>
            {/*<!-- Start Preloader Area Set this up -->*/}
            {/*<div className="preloader-area">
                <div className="spinner">
                    <div className="inner">
                        <div className="disc"></div>
                        <div className="disc"></div>
                        <div className="disc"></div>
                    </div>
                </div>
            </div>*/}
           {/* <!-- End Preloader Area -->*/}
    

    {/*
    
  <MenuLabel htmlFor="navi-toggle" onClick={handleClick}>
                        <Icon clicked={click}>&nbsp;</Icon>
                      </MenuLabel>
                      <NavBackground clicked={click}>&nbsp;</NavBackground>
    */}
            {/*<!-- Top Header -->*/}
        <header className="fixed-header">
            <div className="header-menu">
                <div className="navbar">
                    <div className="nav-item d-none d-sm-block">
                        <div className="hero-content ">
                            <Link to="/"><img src={logo} alt="Ford Maverick Nation" style={{height: "120px", width: "120px"}}/></Link>
                        
                        </div>
                    </div>
                    <div className="top-menu-mobile hidden-menu">
                      <MenuLabel htmlFor="navi-toggle" onClick={handleClick}>
                        <Icon clicked={click}>&nbsp;</Icon>
                      </MenuLabel>
                      <NavBackground clicked={click}>&nbsp;</NavBackground>
                    </div>
                    <div className="nav-item nav-top-menu">
                   

                        <nav id="dropdown" className="template-main-menu">
                     
                            <ul className="menu-content">
                                <li className="header-nav-item">
                                    <NavLink to="/" className="menu-link active">Home</NavLink>
                                </li>
                                <li className="header-nav-item">
                                    <NavLink to="/news" className="menu-link active">News</NavLink>
                                </li>
                                <li className="header-nav-item">
                                    <NavLink to="/forum" className="menu-link active">Forum</NavLink>
                                </li>
                                <li hidden className="header-nav-item">
                                    <NavLink hidden  to="/shop" className="menu-link active">Shop</NavLink>
                                </li>
                                <li className="header-nav-item">
                                    <NavLink to="/contactus" className="menu-link active">Contact Us</NavLink>
                                </li>
                  
                      
                            </ul>
                        </nav>
           
                    </div>
                    <div className="btn-box">
                      <Navigation clicked={click}>

                    
                                                
                                              
                                          
                                    <List>
                                        <li><ItemLink onClick={handleClick} to="/" className="sub-menu-item">Home</ItemLink></li>
                                    
                                        <li><ItemLink onClick={handleClick} to="/news" className="sub-menu-item"> News</ItemLink></li>
                                        <li><ItemLink onClick={handleClick} to="/forum" className="sub-menu-item">Forum</ItemLink></li>
                                  
                                        <li hidden><ItemLink onClick={handleClick} to="/shop" className="sub-menu-item">Shop</ItemLink></li>
                                        <li><ItemLink onClick={handleClick} to="/contactus" className="sub-menu-item">Contact Us </ItemLink></li>
                                        </List>
                                   
                                   
                         
                      </Navigation>
                    </div>
                    {member &&
                    <div className="nav-item header-control" >
                     
                        <div  className="inline-item d-flex align-items-center">
                           
                            <div hidden className="dropdown dropdown-friend">
                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                    <i className="icofont-users-alt-4"></i>
                                </button>
                       
                            </div>
                            <div hidden className="dropdown dropdown-message">
                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                    <i className="icofont-speech-comments"></i>
                                </button>
                        
                            </div>
                            <div hidden className="dropdown dropdown-notification">
                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                    <i className="icofont-notification"></i><span className="notify-count">3</span>
                                </button>
                         
                            </div>

                            
                        </div>
                        <div className="inline-item">
                            <div className="dropdown dropdown-admin">
                            <span className="media-body">
                                <span className="item-title" style={{color : "white"}}> Hi {currentProfile.firstName} {currentProfile.lastName}</span>
                            </span>
                                <button className="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                    <span className="media">
                                        <span className="item-img">
                                            <img src={currentProfile.profileImageUrl} alt="Chat" style={{height: "44px", width: "44px",background: "white"}}/>
                                            <span className="acc-verified"><i className="icofont-check"></i></span>
                                        </span>
                                       
                                    </span>
                                </button>
                            
                            </div>
                        </div>
                        {member && 
                            <div className="buy-button">
                                <Link to="/logout" className="btn btn-danger">Sign Out</Link>
                            </div>
                        }
                    </div>
                    }
                    { !member &&
                        <div className="col-xl-4 col-lg-3 col-sm-5 col-4 d-flex justify-content-end">
                                <div className="header-action">
                                    <ul>
                                    
                                        <li className="login-btn">
                                            <Link to="/login" className="item-btn"><i className="fas fa-user"></i>Login</Link>
                                        </li>
                                        <li className="login-btn">
                                            <Link to="/registermember"  className="item-btn"><i className="fas fa-user"></i>Register</Link>
                                        </li>
                                    </ul>
                                </div>
                        </div>
                    }
                </div>
            </div>
      
      
     
           
                {/*<!-- End Navbar Area -->*/}
    
            </header>
           {/* <!-- End Header Area -->*/}
           </React.Fragment>
         );
    
}
 
export default NavBar;