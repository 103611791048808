
import React from 'react'
import {Link} from 'react-router-dom';
import Form from '../../common/reusable/form';
import uuid from 'react-uuid'
import Joi from 'joi-browser';
import {toast} from 'react-toastify';

import { createNewForumTopic } from '../../../services/forum/forumTopicManagement';
import Input from '../../common/reusable/input';
import MultiLineInput from '../../common/reusable/multilineinput';
import { getCurrentUserProfile } from '../../../services/members/memberauth';


class AddNewForumTopic extends Form
{
    //protected view
    state={
        data:{
            //topicId:"",
            forumTitle: '',
            topicDescription: '',
            
                
            
        },
        errors:{

        },
        baseState:{

        },
        authorProfile:{
            authorId:'',
            firstName:'',
            lastName:'',
            userName:'',
            profileImageUrl:''

        }
    }

    schema ={
        //topicId: Joi.string().optional(),
        forumTitle: Joi.string().required().label('Forum Title'),
        topicDescription: Joi.string().required().label('Forum Topic Description')
    };
    //set initial state to reset form on successful submit
    constructor(props){
        super(props)
    
        // preserve the initial state in a new object
        this.baseState = this.state ///>>>>>>>>> note this one.
    }
    resetForm = () => {
        this.setState(this.baseState) ///>>>>>>>>> note this one.
    }
    doSubmit = async () =>{

        try{
        const {data,authorProfile} = this.state;
        //get the current location
        const {state} = this.props.location; 
              //call the api method

             

             const forumTitle = data.forumTitle;
             const topicDescription = data.topicDescription;
         
           
              //Need to send this to the api and get the token
              //need to add the author info

              const authorProfileDetail ={
                authorId: authorProfile.memberId,
                firstName: authorProfile.firstName,
                lastName: authorProfile.lastName,
                userName: authorProfile.userName,
                profileImageUrl: authorProfile.profileImageUrl
              }

              const forumTopic ={
                topicId: uuid(),//set a random ID
                forumTitle: forumTitle,
                topicDescription: topicDescription,
                authorProfile: authorProfileDetail


            }
            
            await createNewForumTopic(forumTopic);
            console.log("Submitted");

            //Thank user
            toast.success("Forum Topic Request successfully received.");
            //get the current location
           // const {state} = this.props.location; 
           //reset the form and initial state
           this.resetForm();
            //force a reload of the page
            //GO back to the video management
            window.location = state ? state.from.pathname : "/forum";

            }
            catch(ex){
                if(ex.response && ex.response.status === 400){
                    const errors ={...this.state.errors};
                    errors.forumTitle = ex.response.data;

                    toast.error(ex.response.data);
                    //Update the UI
                    this.setState({errors});
                }
            }

    }


    async componentDidMount(){
        const { data: authorProfile } = await getCurrentUserProfile()
    
    
        if(authorProfile)
        {
            console.log("User Found ");
            console.log(authorProfile );
            this.setState({ authorProfile });
        }
        else
        { 
            console.log("No User Found ");

        }
        
    }

    render(){
        const {data, errors } = this.state;

        return(
            <React.Fragment>
             
             <div className="bg-link-water">
                <section className="about-us">
                    <div className="container">

                        <div className="forum-topic-add">
                            <h3 className="heading-title">Create New Forum Topic</h3>
                            <div className="block-box">
                                <form onSubmit={this.handleSubmit}>
                                    
                                        <Input
                                            type="text" 
                                            className="form-control"
                                            id="forumTitle"
                                            name="forumTitle"
                                            error={errors.forumTitle}
                                            value={data.forumTitle} 
                                            label="Topic Title (Maximum Length: 80 and Unique Title)" 
                                            placeholder="Enter a unique topic title." 
                                            onChange={this.handleChange}
                                         />
                                 
                                   
                                    

                                      
                                      
                                     <MultiLineInput
                                           name="topicDescription" 
                                           id="topicDescription"
                                            error={errors.topicDescription}
                                            value={data.topicDescription} 
                                            label={"Topic Overview"}
                                           className="form-control textarea" 
                                           cols="30"
                                            rows="5"
                                            onChange={this.handleChange}
                                         />
                                 
                                
                                    <div className="form-group">
                                       
                                        {this.renderButton("Create New Topic")}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            </React.Fragment>

    )
    }
}

export default AddNewForumTopic;