
import React, { Component } from 'react'
import {Link,Redirect} from 'react-router-dom';
import "../../../index.css"
import { authLoginMember, getCurrentUser } from '../../../services/members/memberauth';
import logo from '../../../styletheme/assets/logo/complex-logo.png';
import Joi from 'joi-browser';
import {toast} from 'react-toastify';
import Form from '../../common/reusable/form';
import Input from '../../common/reusable/input';

class LoginPage extends Form
{
    state = {
        data:{
            emailAddress: '',
            password: ''
        },
        errors:{
           
        }
    };
    //Joi validation schema
    schema ={
        emailAddress: Joi.string().email({ minDomainSegments: 2, tlds: { allow: ['com', 'edu'] } }).required().label('Email Address'),//use joi in node as well
        password: Joi.string().required().label('Password')
    }
 

    componentDidMount(){
      //  this.emailAddress.current.focus();
    }

    doSubmit = async () =>{
        //login function
        try{
            const {data} = this.state;
            const accountPayLoad ={
                emailAddress: data.emailAddress,
                password: data.password
            }
            
            await authLoginMember(accountPayLoad);

            //get the current location
            const {state} = this.props.location; 
           
            //force a reload of the page
            window.location = state ? state.from.pathname : "/memberhome";

        }
        catch(ex){
            if(ex.response && ex.response.status === 400){
                const errors ={...this.state.errors};
                errors.emailAddress = ex.response.data;
                //Update the UI
                this.setState({errors});
            }
        }
    }
    render() { 
       //if user logged in
        if(getCurrentUser()) return <Redirect to="/memberhome"/>;

        const {data, errors } = this.state;

        return(
            <React.Fragment>
            <div className="bg-link-water">
    
                <div className="loginPageStyle " style={{paddingTop: "10%",}}>
                    <div className="block-box" >
                        <div className="login-content ">
                                        
                                <div className="item-logo" style={{marginLeft: "auto",marginRight: "auto",textAlign: 'center'}}>
                                    <br/>
                                        <Link to="/"><img src={logo} alt="logo" style={{height: "30%", width: "30%"}}/></Link>
                                    </div>
                                <div style={{paddingBottom: '50px', paddingLeft: '50px',paddingRight: '50px'}}>

                                
                                            <h3 className="item-title">Sign Into <span>Your Account</span></h3>
                                    
                                            <form onSubmit={this.handleSubmit}>
                                            
                                            <Input
                                                    type="email" 
                                                    className="form-control"
                                                    id="emailAddress"
                                                    name="emailAddress"
                                                    error={errors.emailAddress}
                                                    value={data.emailAddress} 
                                                    label="Email Address" 
                                                    placeholder="Please enter your Email Address." 
                                                    onChange={this.handleChange}
                                                />
                                            
                                                <Input
                                                    type="password" 
                                                    className="form-control"
                                                    id="password"
                                                    name="password"
                                                    error={errors.password}
                                                    value={data.password} 
                                                    label="Password" 
                                                    placeholder="Please type a strong Password that you will remember." 
                                                    onChange={this.handleChange}
                                                />
                                                <div hidden className="form-group reset-password">
                                                    <a href="#">* Reset Password</a>
                                                </div>
                                            
                                                <div className="form-group">
                                                    {this.renderButton("Login")}
                                                </div>
                                            </form>
                                    <div className="account-create">
                                        Not a member as yet? <Link to="/registermember">Register Now</Link>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
            
            </div>
        </div>
    </React.Fragment>

    )
    }
}

export default LoginPage;