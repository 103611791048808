
import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import "../../../index.css"
import { loginWithJwt, registerNewMember } from '../../../services/members/memberauth';
import logo from '../../../styletheme/assets/logo/complex-logo.png';
import Form from '../../common/reusable/form';
import uuid from 'react-uuid'
import Joi from 'joi-browser';
import {toast} from 'react-toastify';
import Input from '../../common/reusable/input';


class RegisterMemeberPage extends Form
{
    state = {
        data:{
        
               //memberId:'',
                firstName:'',
               // middleName:'',
                userName:'',
                lastName:'',
                emailAddress:'',
                password: '',
                //profileImageUrl:'',
            
                //recordCreatedDate:'',
                //isPublic:true,
                
            

        },
        errors:{
           
        }
    };
    //Joi validation schema
    schema ={
        emailAddress: Joi.string().email({ minDomainSegments: 2, tlds: { allow: ['com'] } }).required().label('Email Address'),//use joi in node as well
        password: Joi.string().required().label('Password'),
        firstName: Joi.string().required().label('First Name'),
        lastName: Joi.string().required().label('Last Name'),
        userName: Joi.string().required().label('UserName'),
      //  middleName: Joi.string().optional().label('Middle Name'),
       // profileImageUrl: Joi.string().optional().label('Profile Image'),//need a hint what this is
       // isPublic: Joi.bool().optional(),
    }
 

    async componentDidMount(){
  
    
   
    }

  
     doSubmit = async () =>{
        //handle the submission
          try{
            const {data} = this.state;
            const emailAddress = data.emailAddress;
            const password = data.password;
            const firstName = data.firstName;
            const lastName = data.lastName;
            const userName = data.userName;
            const middleName = "N/A";
            const profileImageUrl = "https://firebasestorage.googleapis.com/v0/b/keijaoh-576a0.appspot.com/o/defaultImages%2Fsimple-logo.png?alt=media&token=44684ec1-fb93-40b0-a187-0dc60119fce9";
       
             //Need to send this to the api and get the token
             const memberProfileDetail ={
               memberId: uuid(),//set a random ID
               emailAddress: emailAddress,
               password: password,
               firstName: firstName,
               lastName: lastName,
               middleName: middleName,
               profileImageUrl: profileImageUrl,
               userName: userName,
               isPublic: true

           }
           console.log(memberProfileDetail);
           //return;
              //register and enroll the student
                const response = await registerNewMember(memberProfileDetail);

               //console.log(response);
                loginWithJwt(response.headers['x-auth-token']);
                //if successful redirect to student dashboard where the courses registered for appears etc
                toast.success("Your Profile Successfully Created!");
                //go to student dashboard
                window.location = "/memberhome";

          
          
           

        }
        catch(ex){
            if(ex.response && ex.response.status === 400){
                const errors = { ...this.state.errors};
                errors.emailAddress = ex.response.data;

                console.log(ex.response.data);
                toast.error(ex.response.data);
                //update UI
                this.setState({errors});
            }
        }
    }

    render() { 
        const {data,errors} = this.state;


        return(
            <React.Fragment>
                <div className="bg-link-water">
    
                    <div className="loginPageStyle " style={{paddingTop: "10%",}}>
                <div className="block-box" >
                        <div className="login-content ">
                        
                            <div className="item-logo" style={{marginLeft: "auto",marginRight: "auto",textAlign: 'center'}}>
                            <br/>
                                <Link to="/"><img src={logo} alt="logo" style={{height: "30%", width: "30%"}}/></Link>
                            </div>
                            
                            <div style={{paddingBottom: '50px', paddingLeft: '50px',paddingRight: '50px'}}>
                                    <h3 className="item-title">Sign Up Your Account</h3>
                                    <form onSubmit={this.handleSubmit}>
                                    
                                    <Input
                                            type="text" 
                                            className="form-control"
                                            id="userName"
                                            name="userName"
                                            error={errors.userName}
                                            value={data.userName} 
                                            label="User Name" 
                                            placeholder="Please enter your UserName." 
                                            onChange={this.handleChange}
                                         />
                                        <Input
                                            type="text" 
                                            className="form-control"
                                            id="firstName"
                                            name="firstName"
                                            error={errors.firstName}
                                            value={data.firstName} 
                                            label="First Name" 
                                            placeholder="Please enter your First Name." 
                                            onChange={this.handleChange}
                                         />
                                      
                                    
                                        <Input
                                            type="text" 
                                            className="form-control"
                                            id="lastName"
                                            name="lastName"
                                            error={errors.lastName}
                                            value={data.lastName} 
                                            label="Last Name" 
                                            placeholder="Please enter your Last Name." 
                                            onChange={this.handleChange}
                                         />
                                    
                                           
                                        <Input
                                            type="email" 
                                            className="form-control"
                                            id="emailAddress"
                                            name="emailAddress"
                                            error={errors.emailAddress}
                                            value={data.emailAddress} 
                                            label="Email Address" 
                                            placeholder="Please enter your Email Address." 
                                            onChange={this.handleChange}
                                         />
                                    
                                        <Input
                                            type="password" 
                                            className="form-control"
                                            id="password"
                                            name="password"
                                            error={errors.password}
                                            value={data.password} 
                                            label="Password" 
                                            placeholder="Please type a strong Password that you will remember." 
                                            onChange={this.handleChange}
                                         />
                                        <div className="form-group">
                                        
                                            {this.renderButton("Complete Registration")}
                                        </div>
                                    </form>
                                </div>
                        </div>
                    
                        </div>
                        
                    </div>
                </div>
            </React.Fragment>

    )
    }
}

export default RegisterMemeberPage;