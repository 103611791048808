
import React, { Component } from 'react'
import {Link} from 'react-router-dom';


class MemberHome extends Component
{

    render(){

        return(
            <React.Fragment>
             
             <div className="bg-link-water">
                <section className="about-us">
                    <div className="container">
                        <h1>Member Home Page</h1>
                        <h3>We are sorry, this page is still under construction. Page coming soon but, click on Forum to use the other parts of the website. Thanks for your understanding.</h3>
                    </div>
                </section>
            </div>
            </React.Fragment>

    )
    }
}

export default MemberHome;