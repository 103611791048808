
import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import logo from '../../../styletheme/assets/logo/simple-logo.png';
import cover1 from "../../../styletheme/media/figure/cover_1.jpg"
import group1 from "../../../styletheme/media/groups/user_group1.jpg" 
import chat1 from "../../../styletheme/media/figure/chat_1.jpg"
import chat2 from "../../../styletheme/media/figure/chat_2.jpg"
import chat3 from "../../../styletheme/media/figure/chat_3.jpg"
import chat4 from "../../../styletheme/media/figure/chat_4.jpg"
import chat5 from "../../../styletheme/media/figure/chat_5.jpg"
import group9 from "../../../styletheme/media/groups/groups_9.jpg"
import group10 from "../../../styletheme/media/groups/groups_10.jpg"
import group11 from "../../../styletheme/media/groups/groups_11.jpg"
import group12 from "../../../styletheme/media/groups/groups_12.jpg"
import { fetchAllForumTopicCount, fetchAllForumTopics } from '../../../services/forum/forumTopicManagement';
import { toast } from 'react-toastify';
import TopicCard from '../../common/common/topiccard';
import { fetchAllForumAdminMembers, fetchAllForumMembers, fetchAllMembersCount } from '../../../services/forum/forummembers';

class ForumTopics extends Component
{

    state={
        
        topicsCount: 0,
        totalMembersCount: 0,
        forumTopics:[{
            topicId:'',
            forumTitle: '',
            topicDescription: '',
            numberOfPost: 0,
            activeMemberCount: 0,
            numberOfRepliesToComment: 0,
            likeReactionCount: 0,
            disLikeReactionCount:0,
            supportReactionCount: 0,
            numOfContributors: 0,
            commentReactions:[{
                reactionId:'',
                reactionImageUrl:'',
                reactionAuthorName:'',
                reactionAuthorId:'',

            }],
            mediaAttachments:[{
                mediaId:'',
                mediaUrl:'',
                mediaType:'',
                isBlocked: false
            }],
            authorProfile: {
                authorId:'',
                firstName:'',
                userName:'',
                lastName:'',
                profileImageUrl: ''
            },
            isBlocked: false,
            recordCreatedDate:''
    

        }],
        members:[{
                memberId:'',
                firstName:'',
                userName:'',
                lastName:'',
                emailAddress:'',
        }],
        adminMembers:[{
                memberId:'',
                firstName:'',
                userName:'',
                lastName:'',
                emailAddress:'',
        }]
    }

//
async componentDidMount(){
    const { data: forumTopics } = await fetchAllForumTopics()


    if(forumTopics)
    {
        console.log("Topics ");
        console.log(forumTopics );
        this.setState({ forumTopics });
    }
    else
    { 
        console.log("No Topics");
        toast.warning("Sorry, no forum topics found. Please add one :)");

    }
    const {data: totalMembersCount} = await fetchAllMembersCount()
    this.setState({ totalMembersCount });

    const {data: topicsCount} = await fetchAllForumTopicCount();
    this.setState({ topicsCount });

    //fetch all admin members
    const {data: adminMembers} = await fetchAllForumAdminMembers();

    if(adminMembers){
        this.setState({adminMembers});
    }

    //fetch members
    
    const {data: members} = await fetchAllForumMembers();

    if(members){
        this.setState({members});
    }
    
}
    render(){
        const { totalMembersCount,topicsCount,forumTopics,adminMembers,members} = this.state
        return(
            <React.Fragment>
             
             <div className="bg-link-water">
                <section className="about-us">
                <div className="container">
                <div className="banner-user banner-forum">
                    <div className="banner-content">
                        <div className="media">
                            <div className="item-img">
                                <img src={logo} alt="User" style={{height: "115px", width: "115px"}}/>
                            </div>
                            <div className="media-body">
                                <h3 className="item-title">Ford Maverick Nation Topics</h3>
                                <div className="item-subtitle">Dont see a topic? Create one, start a trend!</div>
                                <ul hidden className="item-social">
                                    <li><a href="#" className="bg-fb"><i className="icofont-facebook"></i></a></li>
                                    <li><a href="#" className="bg-twitter"><i className="icofont-twitter"></i></a></li>
                                    <li><a href="#" className="bg-dribble"><i className="icofont-dribbble"></i></a></li>
                                    <li><a href="#" className="bg-youtube"><i className="icofont-brand-youtube"></i></a></li>
                                    <li><a href="#" className="bg-behance"><i className="icofont-behance"></i></a></li>
                                </ul>
                                <ul className="user-meta">
                              
                                    <li>Topics: <span>{topicsCount}</span></li>
                                    <li>Members: <span>{totalMembersCount}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
        
                <div className="row">
                    <div className="col-xl-8">
                        <div className="block-box user-search-bar">
                            <div className="box-item search-box">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search via Topic"/>
                                    <div className="input-group-append">
                                        <button className="search-btn" type="button"><i className="icofont-search"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="box-item search-filter">
                            <Link to="/addnewforumtopic" className="btn btn-success" type="button">Add New Topic</Link>
                            </div>
                        </div>
                    
                                 <div id="user-view" className="user-list-view">
                                    <div className="row gutters-20">
                                    {forumTopics.map((value,index)=>(
              
                                                <TopicCard 
                                                authorName={value.authorProfile.firstName + " " + value.authorProfile.lastName} 
                                                topicDescription={value.topicDescription} 
                                                forumTitle={value.forumTitle} 
                                                
                                                path= {`/forumdetail/${value.topicId}`}
                                                key={index}
                                                    mediaAttachments={value.mediaAttachments} 
                                                    numberOfPost ={value.numberOfPost} 
                                                    numOfContributors ={value.numOfContributors} 
                                                    //contributors={}
                                                />
                                
            
        
                                 ))}
                                       
                                
                                

                                    </div>
                    <div hidden className="pagination">
                        <ul>
                            <li><a href="#" className="active">1</a></li>
                            <li><a href="#">2</a></li>
                        </ul>
                    </div>
                </div>
                    </div>
                    <div className="col-xl-4 widget-block widget-break-lg">

                        <div className="widget widget-memebers">
                            <div className="widget-heading">
                                <h3 className="widget-title">Group Administrators</h3>
                              
                            </div>
                            <div className="members-list">
                                {/* Row Start*/}

                                {adminMembers.map((value,index)=>(
              
                                        <div key={index} className="media">
                                            <div className="item-img">
                                                <a href="#">
                                                    <img src={value.profileImageUrl} alt="Chat" style={{height: "44px", width: "44px",background: "white"}}/>
                                                </a>
                                            </div>
                                            <div className="media-body">
                                                <h4 className="item-title"><a href="#">{value.firstName} {value.lastName}</a></h4>
                                                <div className="item-username">@{value.userName} </div>
                                                <div className="member-status online"><i className="icofont-check"></i></div>
                                            </div>
                                        </div>



                                ))}
                           

                                 {/* Row End*/}

                            </div>
                        </div>
                        <div className="widget widget-memebers">
                            <div className="widget-heading">
                                <h3 className="widget-title">Members</h3>
                             
                            </div>
                            <ul hidden className="nav nav-tabs" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-toggle="tab" href="#newest-member" role="tab" aria-selected="true">NEWEST</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#popular-member" role="tab" aria-selected="false">POPULAR</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-toggle="tab" href="#active-member" role="tab" aria-selected="false">ACTIVE</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="newest-member" role="tabpanel">
                                    <div className="members-list">
                                  {/* Row Start*/}
                                    {members.map((value,index)=>(

                                        <div key={index} className="media">
                                            <div className="item-img">
                                                <a href="#">
                                                     <img src={value.profileImageUrl} alt="Chat" style={{height: "44px", width: "44px",background: "white"}}/>
                                                </a>
                                            </div>
                                            <div className="media-body">
                                                <h4 className="item-title"><a href="#">{value.userName}</a></h4>
                                                <div className="member-status offline"><i className="icofont-check"></i></div>
                                            </div>
                                        </div>

                                    ))}
                                                            

                                    {/* Row End*/}

                                    </div>
                                </div>
                     
                            </div>
                        </div>
               
                    </div>
                </div>
            </div>
                </section>
            </div>
            </React.Fragment>

    )
    }
}

export default ForumTopics;