import http from '../general/httpService'
import { apiEndpoint } from '../../constants/config.json'
import jwtDecode from 'jwt-decode'

//declaring the constants
const tokenKey = 'fmn_token'

http.setJwt(getJwt())

//TODO implement the nodejs authenication methods using mosh nodejs course
export function registerNewMember(member) {
  const fullApiEndpoint = 
    apiEndpoint + '/authentication/api/create_new_member_profile'
  //need to write to firebase then the server
  // console.log("About to send out curric info");
  // console.log(courseCurriculumId);

  return http.post(fullApiEndpoint, {
    memberId: member.memberId,
    userName: member.userName,
    firstName: member.firstName,
    middleName: member.middleName,
    lastName: member.lastName,
    emailAddress: member.emailAddress,
    password: member.password,
    profileImageUrl: member.profileImageUrl,
    isPublic: true
   
  })
}

//login method
//

export async function authLoginMember(accountPayLoad) {
  const fullApiEndpoint = apiEndpoint + '/authentication/api/login_member_user'
  //emailAddress
  //password
  const { data: jwt } = await http.post(fullApiEndpoint, {
    emailAddress: accountPayLoad.emailAddress,
    password: accountPayLoad.password,
  }) //need to get the response

  localStorage.setItem(tokenKey, jwt)
}

export function loginWithJwt(jwt) {
  localStorage.setItem(tokenKey, jwt)
}

export function logout() {
  localStorage.removeItem(tokenKey)
}

export function getCurrentUser() {
  try {
    //get current user
    const jwt = localStorage.getItem(tokenKey)
    return jwtDecode(jwt)
  } catch (ex) {
    return null
  }
}

export function getJwt() {
  return localStorage.getItem(tokenKey)
}

//get current user profile details

export function getCurrentUserProfile() {
  const fullApiEndpoint = apiEndpoint + '/authentication/api/me'
  return http.get(fullApiEndpoint)
}
